import { trans } from '@spotahome/soyuz/client';

import { showToast } from '../Toast';

const TOAST_CONFIG = {
  className: 'login-toast',
  autoClose: 10000
};

export const handleToast = message => {
  showToast(
    {
      renderContent: () => message
    },
    TOAST_CONFIG
  );
};

export const handleSuccessToast = (isFirstLogin, activationSuccess) => {
  if (isFirstLogin) {
    handleToast(trans('login.success'));
  } else if (activationSuccess) {
    handleToast(trans('signup.validationActivationSuccess'));
  }
};

export const handleErrorToast = errorResponse => {
  if (errorResponse && errorResponse.status === 403) {
    handleToast(errorResponse.data.error);
  } else {
    handleToast(trans('login.error'));
  }
};

export default {
  handleSuccessToast,
  handleErrorToast,
  handleToast
};
