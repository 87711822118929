import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 12,
    height: 12
  },
  normal: {
    width: 16,
    height: 16
  },
  big: {
    width: 20,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark,
  medium: Colors.medium
};

const MyIcon = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 19 22"
    className={className}
    aria-label="my-icon"
  >
    <path
      d="M18.6455 9.10767C16.865 9.14858 15.2032 8.57581 13.7787 7.51209V14.8354C13.7787 17.6174 12.1169 20.0721 9.58457 21.054C7.09182 22.0359 4.24296 21.2995 2.502 19.213C0.72146 17.0855 0.40492 14.0989 1.67108 11.6442C2.93724 9.2304 5.54869 7.8803 8.23928 8.24851V11.9306C7.01269 11.5215 5.66739 11.9715 4.91561 13.0352C4.2034 14.1399 4.2034 15.5718 4.95518 16.6355C5.70696 17.6992 7.05226 18.1493 8.23928 17.7401C9.46587 17.331 10.2968 16.1446 10.2968 14.8354V0.516113H13.7787C13.7787 0.84341 13.7787 1.1298 13.8579 1.45709C14.0953 2.80719 14.847 3.99365 15.9945 4.73006C16.7463 5.26192 17.6959 5.54831 18.6455 5.54831V9.10767Z"
      fill={THEMES[theme]}
    />
  </svg>
);

MyIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'medium'])
};

export default MyIcon;
