import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../FooterSimplified.module.scss';

const cx = classNames.bind(styles);

const FooterSimplifiedBlockList = ({
  hasSocial = false,
  isInline = false,
  centered = false,
  children
}) => (
  <ul
    className={cx(styles['footer__block-list'], {
      [styles['footer__block-list--social']]: hasSocial,
      [styles['footer__block-list--inline']]: isInline,
      [styles['footer__block-list--centered']]: centered
    })}
  >
    {children}
  </ul>
);

FooterSimplifiedBlockList.propTypes = {
  hasSocial: PropTypes.bool,
  isInline: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FooterSimplifiedBlockList;
