import { useCallback } from 'react';
import pathToRegexp from 'path-to-regexp';

import {
  getPagePath,
  useSoyuzServer,
  useSoyuzLocales
} from '@spotahome/soyuz/client';

export const useLocaleChange = () => {
  const { currentRoute } = useSoyuzServer();
  const { current: currentLocale } = useSoyuzLocales();
  const onChange = useCallback(
    locale => {
      const keys = [];
      // Get current path with no params
      const currentPath = getPagePath(currentRoute, { locale: currentLocale });
      // Convert current path without params into a regexp. Keys will hold param names
      const currentPathRegExp = pathToRegexp(currentPath, keys);
      // Get params from current pathname
      const [, ...pathParams] = currentPathRegExp.exec(
        window.location.pathname
      );

      // Format params object using the name and the values from the current path
      const params = pathParams.reduce((acc, currentParam, index) => {
        if (!currentParam) {
          return acc;
        }
        const paramName = keys[index].name;

        return {
          ...acc,
          [paramName]: currentParam
        };
      }, {});

      window.location =
        getPagePath(currentRoute, { locale, params }) + window.location.search;
    },
    [currentRoute, currentLocale]
  );

  return onChange;
};

export default {
  useLocaleChange
};
