import PropTypes from 'prop-types';

import classNames from 'classnames';

import LocationIcon from '@spotahome/ui-library/src/tenant/components/icons/LocationIcon';
import StarIcon from '@spotahome/ui-library/src/tenant/components/icons/StarIcon';

import './Testimonial.scss';

const Testimonial = ({
  className = '',
  id,
  stars,
  comment,
  author,
  country
}) => {
  const classes = classNames('testimonial', className);

  return (
    <div className={classes}>
      <div className="testimonial__stars">
        {Array.from({ length: stars }).map((_, index) => (
          <StarIcon key={`${id}_${index}`} />
        ))}
      </div>
      <div className="testimonial__comment">{comment}</div>
      <div className="testimonial_author">
        {author}
        <div className="testimonial__country">
          <LocationIcon fill="black" width="14" height="14" /> {country}
        </div>
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  stars: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
};

export default Testimonial;
