import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Count.module.scss';

const Count = ({ value }) => (
  <div
    className={classNames(styles.count, { [styles.loading]: value === null })}
  >
    {value > -1 ? value : ''}
  </div>
);

Count.propTypes = {
  value: PropTypes.number
};
Count.defaultProps = {
  value: null
};

export default Count;
