import { createContext, useContext } from 'react';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import { useLocaleChange } from '../../hooks/useLocaleChange';

const LocaleContext = createContext({
  availableLocales: null,
  onLocaleChange: null
});

export const LocaleProvider = ({
  children,
  availableLocales,
  onLocaleChange
}) => (
  <LocaleContext.Provider value={{ availableLocales, onLocaleChange }}>
    {children}
  </LocaleContext.Provider>
);

export const useLocaleProviderChange = () => {
  const localeContext = useContext(LocaleContext);
  const handleLocaleChangeFallback = useLocaleChange();

  return localeContext.onLocaleChange || handleLocaleChangeFallback;
};

export const useAvailableLocales = () => {
  const { ui: uiLocales } = useSoyuzLocales();
  const localeContext = useContext(LocaleContext);

  return localeContext.availableLocales || Object.keys(uiLocales);
};

export default {
  LocaleProvider,
  useLocaleProviderChange,
  useAvailableLocales
};
