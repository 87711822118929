/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Section, useCitiesContext } from '@spotahome/ui-library';
import { trans } from '@spotahome/soyuz/client';

import classNames from 'classnames';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import {
  LANDING_SECTIONS,
  TOP_CITIES,
  SEO_LINK_CITIES_KEYS
} from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import { CarouselArrows } from './MultipleItemsCarousel';

import CityCarousel from './CityCarousel';

// styles
import './CitiesShowcase.scss';

import CityList from './CityList';
import CityListRedesign from './CityListRedesign';

const NUMBER_OF_ITEM_PER_SWIPE_DESKTOP = 4;
const NUMBER_OF_ITEM_PER_SWIPE_MOBILE = 1;
const NUMBER_OF_ITEM_PER_SWIPE_TABLET = 2;

const REDESIGN_CARD_WIDTH = 270;

const CitiesShowcase = ({ deviceType, isRedesign = false }) => {
  const [numberOfItems, setNumberOfItems] = useState(4);
  const { cities } = useCitiesContext();
  const carouselContainerRef = useRef(null);
  const topCitiesKeys = Object.keys(TOP_CITIES);
  const carouselCities = topCitiesKeys
    .map(key => cities.find(city => city.id === key))
    .filter(Boolean);

  const listCities = cities
    .filter(
      city =>
        !topCitiesKeys.includes(city.id) &&
        SEO_LINK_CITIES_KEYS.includes(city.id)
    )
    .sort((a, b) => a.cityName.localeCompare(b.cityName));
  const [page, setPage] = useState(0);

  const isDesktop = deviceType === 'desktop';
  const isTablet = deviceType === 'tablet';
  const isMobile = deviceType === 'smartphone';

  const getNumberOfItems = () => {
    if (isDesktop) {
      return NUMBER_OF_ITEM_PER_SWIPE_DESKTOP;
    } else if (isTablet) {
      return NUMBER_OF_ITEM_PER_SWIPE_TABLET;
    }
    return NUMBER_OF_ITEM_PER_SWIPE_MOBILE;
  };

  const calculateNumberOfItems = () => {
    if (!isRedesign || cities.length === 0) {
      return setNumberOfItems(getNumberOfItems());
    }

    if (isMobile) {
      return setNumberOfItems(NUMBER_OF_ITEM_PER_SWIPE_MOBILE);
    }

    const containerWidth = carouselContainerRef.current.offsetWidth;
    const dynamicNumberOfItems = Math.floor(
      (containerWidth * 0.9) / REDESIGN_CARD_WIDTH
    );
    setNumberOfItems(Math.min(dynamicNumberOfItems, carouselCities.length));

    return null;
  };

  useEffect(() => {
    calculateNumberOfItems();

    window.addEventListener('resize', calculateNumberOfItems);

    return () => {
      window.removeEventListener('resize', calculateNumberOfItems);
    };
  }, [cities]);

  const sectionClasses = classNames({
    'cities-showcase__top-cities': !isRedesign,
    'cities-showcase__top-cities--redesign': isRedesign
  });

  const headerClasses = classNames('cities-showcase__header', {
    'cities-showcase__header--redesign': isRedesign
  });

  const headerTitleClasses = classNames('cities-showcase__header-title', {
    'cities-showcase__header-title--redesign': isRedesign
  });

  const arrowsClasses = classNames('cities-showcase__arrows', {
    'cities-showcase__arrows--redesign': isRedesign
  });

  const carouselClasses = classNames('cities-showcase__carousel', {
    'cities-showcase__carousel--redesign': isRedesign
  });

  return (
    <>
      <Section background="light" className={sectionClasses}>
        <div className="cities-showcase">
          <div className={headerClasses}>
            <h2 className={headerTitleClasses}>
              {trans('landingpage.label.cities')}
            </h2>
            <div className={arrowsClasses}>
              <CarouselArrows
                page={page}
                setPage={setPage}
                maxPage={Math.ceil(carouselCities.length / numberOfItems)}
                isRedesign={isRedesign}
              />
            </div>
          </div>
          <div className={carouselClasses} ref={carouselContainerRef}>
            <CityCarousel
              cities={carouselCities}
              page={page}
              numberOfItems={numberOfItems}
              isRedesign={isRedesign}
              isMobile={isMobile}
            />
          </div>
        </div>
      </Section>
      <Section background="light" className="cities-showcase__other-cities">
        {isRedesign ? (
          <CityListRedesign cities={listCities} numberOfColumns={6} />
        ) : (
          <CityList cities={listCities} numberOfColumns={6} />
        )}
      </Section>
    </>
  );
};

CitiesShowcase.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isRedesign: PropTypes.bool
};

export default withInViewEvent(LANDING_SECTIONS.citiesShowcase)(CitiesShowcase);
