import colors from '../../scss/variables/colors.module.scss';

const themes = {
  base: colors.primary,
  secondary: colors.interaction,
  light: 'white',
  dark: colors.dark,
  interactionDark: colors['interaction-dark'],
  medium: colors.medium,
  instantBooking: colors.tertiary,
  topLandlord: colors.secondary,
  favourite: colors.danger,
  landlord: colors.secondary,
  info: colors.info,
  interaction: colors.interaction
};

export default themes;
