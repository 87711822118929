import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 12,
    height: 12
  },
  normal: {
    width: 16,
    height: 16
  },
  big: {
    width: 20,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark,
  medium: Colors.medium
};

const FacebookIcon = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 22 22"
    className={className}
    aria-label="facebook-icon"
  >
    <g clipPath="url(#clip0_2905_1178)">
      <path
        d="M21.0932 10.7354C21.0932 4.9297 16.3866 0.223145 10.5809 0.223145C4.77515 0.223145 0.0685959 4.9297 0.0685959 10.7354C0.0685959 15.6653 3.4628 19.8021 8.04153 20.9382V13.948H5.8739V10.7354H8.04153V9.35117C8.04153 5.77321 9.66084 4.11479 13.1736 4.11479C13.8397 4.11479 14.9889 4.24556 15.459 4.37592V7.28782C15.2109 7.26175 14.7799 7.24871 14.2446 7.24871C12.521 7.24871 11.855 7.90173 11.855 9.59926V10.7354H15.2887L14.6987 13.948H11.855V21.1708C17.0594 20.5421 21.0932 16.1101 21.0932 10.7354Z"
        fill={THEMES[theme]}
      />
    </g>
    <defs>
      <clipPath id="clip0_2905_1178">
        <rect
          width="20.9676"
          height="20.9676"
          fill="white"
          transform="translate(0.580872 0.516113)"
        />
      </clipPath>
    </defs>
  </svg>
);

FacebookIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default FacebookIcon;
