import ThemeProvider, {
  useTheme,
  useThemeFlag,
  ThemeContext,
  ERASMUSU_THEME,
  SPOTAHOME_THEME
} from './ThemeProvider';

export default ThemeProvider;
export {
  useTheme,
  useThemeFlag,
  ThemeContext,
  ERASMUSU_THEME,
  SPOTAHOME_THEME
};
