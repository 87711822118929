import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './BaseButton.module.scss';

const cx = classNames.bind(styles);

const BaseButton = forwardRef(
  (
    {
      id,
      onClick,
      color,
      disabled,
      dataTest,
      className,
      roundedCorners,
      renderContent,
      renderIcon,
      renderTrailingIcon,
      dropShadow,
      fullWidth,
      size,
      href,
      target,
      type
    },
    ref
  ) => {
    const btnClassName = cx(
      'base-button',
      `base-button--color-${color}`,
      `base-button--size-${size}`,
      {
        'base-button--rounded-corners': roundedCorners,
        'base-button--circular-button': !renderContent,
        'base-button--drop-shadow': dropShadow,
        'base-button--full-width': fullWidth
      },
      className
    );

    const iconClassName = cx('base-button__icon');
    const trailingIconClassName = cx('base-button__trailing-icon');
    const textClassName = cx('base-button__text');

    const content = (
      <>
        {renderIcon && (
          <span className={iconClassName}>{renderIcon && renderIcon()}</span>
        )}
        {renderContent && (
          <span className={textClassName}>
            {renderContent && renderContent()}
          </span>
        )}
        {renderTrailingIcon && (
          <span className={trailingIconClassName}>
            {renderTrailingIcon && renderTrailingIcon()}
          </span>
        )}
      </>
    );

    if (href) {
      return (
        <a
          id={id}
          href={href}
          target={target}
          onClick={onClick}
          data-test={dataTest}
          className={btnClassName}
          ref={ref}
        >
          {content}
        </a>
      );
    }

    return (
      <button
        id={id}
        // eslint-disable-next-line react/button-has-type
        type={type || 'button'} // This fallback is temporary, until we remove the Button wrapper and BaseButton becomes the only Button.
        onClick={onClick}
        data-test={dataTest}
        disabled={disabled}
        className={btnClassName}
        ref={ref}
      >
        {content}
      </button>
    );
  }
);

BaseButton.defaultProps = {
  id: null,
  onClick: () => {},
  renderContent: null,
  renderIcon: null,
  renderTrailingIcon: null,
  color: 'primary',
  size: 'medium',
  fullWidth: false,
  className: null,
  disabled: false,
  dataTest: null,
  roundedCorners: false,
  dropShadow: false,
  href: '',
  type: 'button',
  target: ''
};

BaseButton.propTypes = {
  id: PropTypes.string,
  renderContent: PropTypes.func,
  renderIcon: PropTypes.func,
  renderTrailingIcon: PropTypes.func,
  className: PropTypes.string,
  roundedCorners: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'tertiary-light',
    'link'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  dropShadow: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top', ''])
};

BaseButton.displayName = 'BaseButton';

export default BaseButton;
