export const ANDROID_KEY = 'android';
export const IOS_KEY = 'ios';
export const MOBILE_APP_BUTTONS = {
  [ANDROID_KEY]: {
    href: 'https://spotahome.app.link/menu_android',
    eventAction: 'menu-icon-android',
    className: 'ga-android-icon-menu',
    imageSrc:
      'https://static.spotahome.com/login_image/google-play.png',
    imageAlt: 'Google Play'
  },
  [IOS_KEY]: {
    href: 'https://spotahome.app.link/menu_ios',
    eventAction: 'menu-icon-ios',
    className: 'ga-ios-icon-menu',
    imageSrc: 'https://static.spotahome.com/login_image/app-store.png',
    imageAlt: 'App Store'
  }
};
