import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const TenantFooter = ({ linkTheme }) => {
  const tenantLinks = [
    {
      url: getPagePath('how-it-works'),
      transKey: 'footer.item.how_it_works'
    },
    {
      url: getPagePath('landings.promotions'),
      transKey: 'footer.item.promotions'
    }
  ];

  return (
    <Fragment>
      <FooterBlockTitle text={trans('footer.label.tenants')} theme="dark" />
      <FooterBlockList>
        {tenantLinks.map(({ transKey, url }) => (
          <FooterLink key={transKey} linkTheme={linkTheme} href={url}>
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </Fragment>
  );
};

TenantFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default TenantFooter;
