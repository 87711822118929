import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { checkRating } from '../../prop-types/rating';

import BackgroundStarsIcon from '../BackgroundStarsIcon';
import StarsIcon from '../StarsIcon';

import styles from './Rating.module.scss';

const lowerValue = 0;
const higherValue = 5;
const normalizeRating = value =>
  Math.min(Math.max(value, lowerValue), higherValue);

const cx = classNames.bind(styles);

const Rating = ({
  rating = 0,
  className = null,
  size = 'normal',
  onClick = () => {}
}) => {
  const validRating = normalizeRating(rating);

  const ratingStyle = {
    right: `${100 - (validRating * 100) / higherValue}%`
  };

  const ratingClassName = cx(styles.rating, styles[size], className);

  return (
    <div className={ratingClassName} onClick={onClick} size={size}>
      <BackgroundStarsIcon className={styles['background-stars']} size={size} />
      <div className={styles['rating-stars']} style={ratingStyle}>
        <StarsIcon size={size} />
      </div>
    </div>
  );
};

Rating.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small']),
  onClick: PropTypes.func,
  rating: checkRating
};

export default Rating;
