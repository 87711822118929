import PropTypes from 'prop-types';

import styles from './MobileMenu.module.scss';

const MobileMenuLink = props => (
  <a {...props} className={styles.link}>
    {props.children}
  </a>
);

MobileMenuLink.propTypes = {
  children: PropTypes.node.isRequired
};

export default MobileMenuLink;
