import PropTypes from 'prop-types';

const SIZES = {
  small: {
    width: 52,
    height: 9
  },
  normal: {
    width: 87,
    height: 15
  }
};

const StarsIcon = ({ size = 'normal' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SIZES[size].width}
    height={SIZES[size].height}
    viewBox="0 0 87 15"
  >
    <g fill="#ffccef" fillRule="evenodd">
      <path d="M8.1 12.3l4.6 2.6-.9-5.4 3.7-3.8-5.1-.8L8.1 0 5.8 4.9l-5.2.8 3.8 3.8-.9 5.4M25.8 12.3l4.6 2.6-.9-5.4 3.7-3.8-5.1-.8L25.8 0l-2.3 4.9-5.2.8 3.8 3.8-.9 5.4M43.5 12.3l4.6 2.6-.9-5.4 3.7-3.8-5.1-.8L43.5 0l-2.3 4.9-5.1.8 3.7 3.8-.9 5.4M61.2 12.3l4.6 2.6-.9-5.4 3.8-3.8-5.2-.8L61.2 0l-2.3 4.9-5.1.8 3.7 3.8-.9 5.4" />
      <path
        fillRule="nonzero"
        d="M78.9 12.3l4.6 2.6-.9-5.4 3.8-3.8-5.2-.8L78.9 0l-2.3 4.9-5.1.8 3.7 3.8-.9 5.4"
      />
    </g>
  </svg>
);

StarsIcon.propTypes = {
  size: PropTypes.oneOf(['normal', 'small'])
};

export default StarsIcon;
