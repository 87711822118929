import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const CollapsibleUp = ({ theme = 'interaction' }) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99988 2.9087L1.58981 7.71765L0.282238 6.24663L6.99989 0.275391L13.7175 6.24663L12.41 7.71765L6.99988 2.9087Z"
      fill={themes[theme]}
    />
  </svg>
);

CollapsibleUp.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default CollapsibleUp;
