import isEmpty from 'lodash.isempty';

import { trans } from '@spotahome/soyuz/client';

import {
  apartmentFeatures,
  bedroomFeatures,
  billsIncluded,
  roomTypes,
  restrictions,
  suitabilities,
  gender,
  extendedFeatureList
} from './FeaturesCollection';

export const BASE_FEATURES = {
  ...apartmentFeatures,
  ...bedroomFeatures
};

export const BILLS_INCLUDED = billsIncluded;

const features = {
  ...BASE_FEATURES,
  ...billsIncluded,
  ...roomTypes,
  ...restrictions,
  ...suitabilities,
  ...gender,
  ...extendedFeatureList
};

const featuresToShowNotIncluded = ['4109'];

const getFeatureFromFeatureId = id => features[id] || {};

const isFeatureEmpty = featureId => isEmpty(getFeatureFromFeatureId(featureId));

const featureHasContent = featureId => !isFeatureEmpty(featureId);

const getOptionSelectedFromFeature = (feature = {}, value) =>
  (feature.options && feature.options[value]) || '';

const getIconFromFeatureId = id => getFeatureFromFeatureId(id).icon;

const getHideInExperimentFromFeatureId = id =>
  !!getFeatureFromFeatureId(id).hideInExperiment;

const getNameFromFeatureId = (id, value) => {
  const feature = getFeatureFromFeatureId(id);
  const options = getOptionSelectedFromFeature(feature, value);

  return extendedFeatureList[id]
    ? `${value} ${extendedFeatureList[id].name}`
    : trans(options);
};

const isFeatureAvailableToShow = (featureId, featureValue) => {
  if (isFeatureEmpty(featureId)) {
    return false;
  }

  return featureValue !== false && featureValue !== 'na';
};

const isFeatureAvailableToShowInNotIncluded = (featureId, featureValue) => {
  if (isFeatureEmpty(featureId)) {
    return false;
  }

  return featuresToShowNotIncluded.includes(featureId)
    ? featureValue === false
    : false;
};

const getFeatureNameList = (featureList = []) =>
  featureList.map(({ id, value }) => getNameFromFeatureId(id, value));

const getFeaturesAsList = (fromFeatures = {}) =>
  Object.keys(fromFeatures)
    .filter(featureHasContent)
    .map(featureId => ({
      id: featureId,
      value: fromFeatures[featureId].value,
      name: getNameFromFeatureId(featureId, fromFeatures[featureId].value),
      icon: getIconFromFeatureId(featureId),
      order: fromFeatures[featureId].order,
      hideInExperiment: getHideInExperimentFromFeatureId(featureId)
    }));

const getAvailableFeaturesList = (featureList = []) =>
  featureList.filter(({ id, value }) => isFeatureAvailableToShow(id, value));

const getUnavailableFeaturesList = (featureList = []) =>
  featureList.filter(({ id, value }) => !isFeatureAvailableToShow(id, value));

const getNotIncludedFeaturesToShowList = (featureList = []) =>
  featureList.filter(({ id, value }) =>
    isFeatureAvailableToShowInNotIncluded(id, value)
  );

export default {
  getIconFromFeatureId,
  getNameFromFeatureId,
  getFeatureNameList,
  isFeatureAvailableToShow,
  isFeatureAvailableToShowInNotIncluded,
  getAvailableFeaturesList,
  getUnavailableFeaturesList,
  getNotIncludedFeaturesToShowList,
  getFeaturesAsList,
  BASE_FEATURES,
  BILLS_INCLUDED
};
