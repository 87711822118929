import PropTypes from 'prop-types';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

const MobileAppButton = ({ eventAction, imageSrc, imageAlt, ...rest }) => {
  const handleClick = () => {
    SoyuzAnalytics.sendGA4Event('app-banner', {
      category: 'app-promotion',
      source: eventAction,
      action: 'open'
    });

    if (typeof rest.onClick === 'function') {
      rest.onClick();
    }
  };

  return (
    <a tabIndex="0" role="button" {...rest} onClick={handleClick}>
      <img src={imageSrc} alt={imageAlt} width="158" height="51" />
    </a>
  );
};

MobileAppButton.propTypes = {
  eventAction: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};

export default MobileAppButton;
