/* eslint-disable react/jsx-handler-names */
import PropTypes from 'prop-types';
import { trans, getPagePath } from '@spotahome/soyuz/client';

import NoSSR from 'react-no-ssr';

import { useThemeFlag } from '../../../../base/ThemeProvider';

import Button from '../../Button';
import LanguageSwitcher from '../../LanguageSwitcher';

import HeaderLink from '../HeaderLink';
import HeaderItem from '../HeaderItem';
import HeaderLogin from '../HeaderLogin';
import HeaderItems from '../HeaderItems';

import linkShape from '../shapes/link.shape';
import loginShape from '../shapes/login.shape';
import themeShape, { DARK_THEME, LIGHT_THEME } from '../shapes/theme.shape';

const HeaderNav = ({
  login = {},
  color = DARK_THEME,
  isTransparent = false,
  showLogin,
  ...links
}) => {
  const {
    listYourProperty = {},
    tenantLandingPage = null,
    howItWorks = {},
    favorites = {},
    help = {}
  } = links;

  const newButtonsEnabled = useThemeFlag('newButtons');

  return (
    <HeaderItems>
      {!!listYourProperty && (
        <HeaderItem>
          <Button
            alt
            color={
              newButtonsEnabled && isTransparent && color === LIGHT_THEME
                ? 'tertiary-light'
                : 'interaction'
            }
            className="ga-home-publish-your-property-top header-button"
            href={getPagePath('externals.landlord-landing')}
            size="small"
            {...listYourProperty}
          >
            {listYourProperty.label || trans('landingpage.button.landlord')}
          </Button>
        </HeaderItem>
      )}
      {!!tenantLandingPage && (
        <HeaderItem>
          <Button
            alt={color === 'dark'}
            color={color === 'light' ? 'filled' : 'primary'}
            className="ga-home-tenant-landing-page-top header-button"
            href={getPagePath('landing')}
            size="small"
            {...tenantLandingPage}
          >
            {tenantLandingPage.label || trans('landingpage.button.tenant')}
          </Button>
        </HeaderItem>
      )}

      {!!howItWorks && (
        <HeaderItem>
          <HeaderLink
            target="_blank"
            className="ga-menu-faqs"
            color={color}
            href={getPagePath('how-it-works')}
            {...howItWorks}
          >
            {howItWorks.label || trans('how_it_works.title')}
          </HeaderLink>
        </HeaderItem>
      )}

      {!!favorites && (
        <HeaderItem>
          <HeaderLink
            className="ga-menu-favorites"
            target="_blank"
            color={color}
            href={getPagePath('marketplace.favorites')}
            rel="nofollow"
            {...favorites}
          >
            {favorites.label || trans('favorites.title.favorites')}
          </HeaderLink>
        </HeaderItem>
      )}

      {showLogin || !!login ? (
        <NoSSR>
          <HeaderLogin {...login} color={color} isMobile={false} />
        </NoSSR>
      ) : null}

      {!!help && (
        <HeaderItem>
          <HeaderLink
            href={getPagePath('support')}
            color={color}
            data-test="header-nav-help"
            target="_self"
            {...help}
          >
            {help.label || trans('help.title')}
          </HeaderLink>
        </HeaderItem>
      )}

      <HeaderItem>
        <HeaderLink color={color}>
          <LanguageSwitcher theme={color} abbreviated />
        </HeaderLink>
      </HeaderItem>
    </HeaderItems>
  );
};

HeaderNav.propTypes = {
  listYourProperty: PropTypes.shape(linkShape),
  howItWorks: PropTypes.shape(linkShape),
  favorites: PropTypes.shape(linkShape),
  help: PropTypes.shape(linkShape),
  tenantLandingPage: PropTypes.shape(linkShape),
  login: PropTypes.shape(loginShape),
  color: themeShape,
  isTransparent: PropTypes.bool,
  showLogin: PropTypes.bool.isRequired
};

export default HeaderNav;
