const withLocalStorage =
  fn =>
  (...params) => {
    try {
      if (window && window.localStorage) {
        return fn(...params);
      }
      return null;
    } catch (error) {
      return null;
    }
  };

export const setItem = withLocalStorage((key, value) => {
  localStorage.setItem(key, value);
});

export const getItem = withLocalStorage(key => localStorage.getItem(key));

export const removeItem = withLocalStorage(key => {
  localStorage.removeItem(key);
});

export const extractItem = withLocalStorage(key => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);

  return value;
});

export default {
  setItem,
  getItem,
  removeItem,
  extractItem
};
