import classNames from 'classnames';
import PropTypes from 'prop-types';

import './HeroContainer.scss';

const HeroContainer = ({ children, className }) => {
  const heroContainerClassname = classNames('hero', className);
  return <div className={heroContainerClassname}>{children}</div>;
};

HeroContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

HeroContainer.defaultProps = {
  className: ''
};

export default HeroContainer;
