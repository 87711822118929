import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const MapIcon = ({ className = '', theme = 'base' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="24"
    viewBox="0 0 19 24"
    className={className}
  >
    <path
      fill={themes[theme]}
      fillRule="evenodd"
      d="M0 9.096C.003 4.076 4.255.006 9.502 0 14.748.008 18.997 4.077 19 9.096c0 3.257-3.166 8.225-9.498 14.904C3.167 17.32 0 12.353 0 9.096zM9.5 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
    />
  </svg>
);

MapIcon.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes)),
  className: PropTypes.string
};

export default MapIcon;
