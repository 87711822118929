import React from 'react';

const StarIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.04837 36.4151L10.0593 23.2947L0 14.4728L13.2723 13.3072L18.4495 0.935303L23.6266 13.3072L36.8989 14.4728L26.8397 23.2947L29.8506 36.4151L18.4495 29.4541L7.04837 36.4151Z"
      fill="#FB6E44"
    />
  </svg>
);

export default StarIcon;
