import classNames from 'classnames';

import styles from '../Footer.module.scss';

const FooterBlock = props => (
  <div className={classNames(styles.footer__block, props.className)}>
    {props.children}
  </div>
);
export default FooterBlock;
