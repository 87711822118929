import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizeMap = {
  small: {
    width: '18px',
    height: '18px'
  },
  normal: {
    width: '24px',
    height: '24px'
  }
};

const CheckBoxChecked = ({ size = 'normal' }) => {
  const width = sizeMap[size].width;
  const height = sizeMap[size].height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="checkbox-enabled" fillRule="nonzero">
          <path
            d="M12,0 C0.545454545,0 0,1.55454545 0,12 C0,22.4454545 1.00909091,24 12,24 C22.9909091,24 24,22.3636364 24,12 C24,1.63636364 23.4545455,0 12,0 Z"
            id="Oval-1"
            fill={Colors.interaction}
          />
          <path
            d="M9.63818182,14.6127273 L9.87818182,14.3727273 L16.5054545,7.74545455 C16.7945455,7.45636364 17.2690909,7.45636364 17.5636364,7.75090909 C17.8581818,8.04545455 17.8581818,8.51454545 17.5636364,8.80909091 L10.1672727,16.2054545 C9.87272727,16.5 9.39818182,16.5 9.10909091,16.2054545 C9.10363636,16.2054545 9.10363636,16.2 9.09818182,16.2 C9.09818182,16.1945455 9.09818182,16.1945455 9.09272727,16.1945455 L6.45272727,13.5545455 C6.16363636,13.26 6.16363636,12.7854545 6.45272727,12.4909091 C6.75272727,12.1963636 7.22181818,12.2018182 7.51636364,12.4909091 L9.63818182,14.6127273 L9.63818182,14.6127273 Z"
            id="Rectangle-348"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

CheckBoxChecked.propTypes = {
  size: PropTypes.oneOf(['small', 'normal'])
};

export default CheckBoxChecked;
