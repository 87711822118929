import PropTypes from 'prop-types';

import styles from './MobileMenu.module.scss';

const MobileMenuList = props => (
  <ul className={styles.list}>{props.children}</ul>
);

MobileMenuList.propTypes = {
  children: PropTypes.node.isRequired
};

export default MobileMenuList;
