import { DateRangeHandler } from '@spotahome/ui-library';

import { LIST_E_COMMERCE, TRACKING_PAGE_TYPES } from '../constants';

import * as mappings from './mappings';

const { getDaysDiff } = DateRangeHandler;
const GA_UNSET_VAL = '(not set)';
const GA_UNSET_NEIGHBOURHOOD_VAL = GA_UNSET_VAL;

const getInstantBookingVariant = isInstantBooking =>
  isInstantBooking ? 'instant_booking' : 'regular_booking';
export const getDailyPrice = pricing => pricing.dailyPrice / 100;
export const getContractDaysCount = pricing =>
  getDaysDiff(pricing.startContractDate, pricing.endContractDate);

export const mapListingToProduct = listing => {
  const {
    type,
    id,
    cityId,
    instantBooking: { isEnabled },
    minimumPrice,
    moveIn,
    moveOut
  } = listing;
  return {
    id,
    brand: cityId,
    name: type,
    category: GA_UNSET_NEIGHBOURHOOD_VAL,
    price: minimumPrice,
    variant: getInstantBookingVariant(isEnabled),
    quantity: getDaysDiff(moveIn, moveOut),
    list: TRACKING_PAGE_TYPES.PRODUCT_PAGE
  };
};

export const mapSearchPageHomecardToProduct = (homecard, position, cityId) => {
  const { type, id, monthlyPrice, displayPrice, isInstantBooking } = homecard;
  return {
    id,
    brand: cityId,
    category: GA_UNSET_NEIGHBOURHOOD_VAL,
    name: mappings.mapHomecardTypeToProductCategory(type),
    price: monthlyPrice?.minimumPrice || displayPrice,
    variant: getInstantBookingVariant(isInstantBooking),
    list: TRACKING_PAGE_TYPES.SEARCH_PAGE,
    position: position + 1
  };
};

export const mapExtraRoomsToProduct = (listing, mainListing, position) => {
  const { id, monthlyPrice } = listing;
  const {
    type,
    instantBooking: { isEnabled },
    cityId
  } = mainListing;
  return {
    id,
    brand: cityId,
    category: GA_UNSET_NEIGHBOURHOOD_VAL,
    name: type,
    price: monthlyPrice.minimumPrice,
    variant: getInstantBookingVariant(isEnabled),
    list: LIST_E_COMMERCE.EXTRA_ROOMS,
    position: position + 1
  };
};

export const mapSimilarPropertyToProduct = (similarProperty, position) => {
  const {
    type,
    id,
    city,
    monthlyPrice,
    instantBooking: { isEnabled }
  } = similarProperty;
  return {
    id,
    brand: city,
    category: GA_UNSET_NEIGHBOURHOOD_VAL,
    name: mappings.mapHomecardTypeToProductCategory(type),
    price: monthlyPrice.minimumPrice,
    variant: getInstantBookingVariant(isEnabled),
    list: LIST_E_COMMERCE.SIMILAR_PROPERTIES,
    position: position + 1
  };
};

export const mapFavoriteToProduct = (favorite, position) => {
  const { type, id, city, monthlyPrice, isInstantBooking } = favorite;
  return {
    id,
    brand: city,
    category: GA_UNSET_NEIGHBOURHOOD_VAL,
    name: type,
    price: monthlyPrice.minimumPrice,
    variant: getInstantBookingVariant(isInstantBooking),
    list: TRACKING_PAGE_TYPES.FAVORITES,
    position: position + 1
  };
};

export const mapExtraRoomToCd = (extraRoom, mainListing, isLogged, locale) => {
  try {
    return {
      ...extraRoom,
      listing: {
        ...mainListing,
        ...extraRoom
      },
      isLogged,
      locale,
      pageType: TRACKING_PAGE_TYPES.PRODUCT_PAGE
    };
  } catch (err) {
    return console.error('Failed to map extra room to cd', { err });
  }
};

export const mapSimilarPropertyToCd = (homecard, isLogged, locale) => {
  try {
    const { instantBooking, monthlyPrice, city } = homecard;
    return {
      listing: {
        ...homecard,
        instantBooking: instantBooking.isEnabled,
        reservationPrice: monthlyPrice.minimumPrice,
        cityId: city,
        type: mappings.mapHomecardTypeToProductCategory(homecard.type)
      },
      isLogged,
      locale,
      pageType: TRACKING_PAGE_TYPES.PRODUCT_PAGE
    };
  } catch (err) {
    return console.error('Failed to map similar property to cd', { err });
  }
};

export const mapFavoriteToCd = (favorite, locale) => {
  try {
    return {
      ...favorite,
      cityId: favorite.city,
      isLogged: true,
      locale,
      pageType: TRACKING_PAGE_TYPES.FAVORITES
    };
  } catch (err) {
    return console.error('Failed to map favorite to cd', { err });
  }
};

export const mapBookNowButtonToECommerce = ({
  pricing,
  listing,
  unavailabilities
}) => {
  try {
    return {
      ...listing,
      minimumPrice: listing.pricing.monthlyPrice.minimumPrice,
      pricing,
      moveIn: pricing.checkIn,
      moveOut: pricing.checkOut,
      unavailabilities,
      currencyCode: pricing.currencyCode,
      reservationPrice: pricing.monthlyPrice,
      tenantFeeGross: pricing.tenantFeeGross,
      totalContractValue: pricing.totalContractValue,
      quantity: 1
    };
  } catch (err) {
    return console.error('Failed to map bookNow to ecommerce', { err });
  }
};

export const mapBookNowButtonToProduct = ({ pricing, listing }) => {
  try {
    return {
      currencyCode: pricing.currencyCode,
      brand: listing.cityId,
      name: listing.type,
      category: GA_UNSET_NEIGHBOURHOOD_VAL,
      list: TRACKING_PAGE_TYPES.PRODUCT_PAGE,
      quantity: getContractDaysCount(pricing),
      price: getDailyPrice(pricing),
      variant: getInstantBookingVariant(listing.instantBooking.isEnabled)
    };
  } catch (err) {
    return console.error('Failed to map bookNow to ecommerce', { err });
  }
};
