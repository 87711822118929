import PropTypes from 'prop-types';

const ChevronDown = ({ className }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.72577L4 6.28133L-2.3072e-07 2.72577L0.899038 1.71436L4 4.47077L7.10096 1.71436L8 2.72577Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default ChevronDown;
ChevronDown.propTypes = {
  className: PropTypes.string
};

ChevronDown.defaultProps = {
  className: ''
};
