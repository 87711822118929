import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MultipleItemsCarousel.scss';

const MultipleItemsCarousel = ({
  itemsPerSwipe,
  items,
  selectedPage,
  isRedesign = false,
  centerItems = false,
  slidePercentage = 90
}) => {
  const newItem = (itemsToGroup, i, numberPerSwipe) => {
    const res = itemsToGroup.slice(i, i + numberPerSwipe);
    // if array has fewer items than expected, empty divs are added to keep style consistency
    if (res.length < numberPerSwipe) {
      const numberToBeAdded = numberPerSwipe - res.length;
      for (let z = 0; z < numberToBeAdded; z += 1) {
        res.push(
          <div
            key={`fake-item${z}`}
            className="multiple-items-carousel-hidden"
          />
        );
      }
    }
    return res;
  };

  const renderCarouselGroup = itemsToRender => {
    const resultsRender = [];

    const itemWrapperClasses = classNames('item-list__wrapper', {
      'item-list__wrapper--centered': centerItems,
      'item-list__wrapper--redesign': isRedesign
    });

    for (let i = 0; i < itemsToRender.length; i += itemsPerSwipe) {
      resultsRender.push(
        <div key={`item-list${i}`} className={itemWrapperClasses}>
          {newItem(itemsToRender, i, itemsPerSwipe)}
        </div>
      );
    }
    return resultsRender;
  };

  return (
    <Carousel
      showThumbs={false}
      showArrows={false}
      selectedItem={selectedPage}
      showIndicators={false}
      showStatus={false}
      centerSlidePercentage={slidePercentage}
      centerMode
      autoPlay={false}
    >
      {renderCarouselGroup(items)}
    </Carousel>
  );
};

MultipleItemsCarousel.propTypes = {
  itemsPerSwipe: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  selectedPage: PropTypes.number.isRequired,
  isRedesign: PropTypes.bool,
  centerItems: PropTypes.bool,
  slidePercentage: PropTypes.number
};

export default MultipleItemsCarousel;
