import gql from 'graphql-tag';
import axios from 'axios';

const SEND_INVITE_EVENT = gql`
  mutation sendInviteEvent($input: SendInviteEventInput!) {
    sendInviteEvent(input: $input)
  }
`;

export const sendInviteEvent =
  client =>
  ({ input }) =>
    client.mutate({
      mutation: SEND_INVITE_EVENT,
      variables: { input }
    });

export const emailSignupWithoutPassword = (options = {}) => {
  const path = '/marketplace/email-signup';
  const headers = {};
  const { cookie } = options;

  if (cookie) {
    headers.cookie = cookie;
  }

  return axios.post(path, options, { headers });
};
