import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import TrustPilotLink from './TrustPilotLink';

const BUSINESS_ID = '54f74fc00000ff00057dd4e0';
export const TEMPLATE_IDS = {
  MINI: '53aa8807dec7e10d38f59f32',
  MICRO_STAR: '5419b732fbfb950b10de65e5'
};

const TrustpilotWidget = ({
  template = TEMPLATE_IDS.MINI,
  theme = 'light',
  locale = 'en',
  onClick = null,
  className = null,
  height = 120
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot && ref.current) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      role="button"
      tabIndex={0}
      data-locale={locale}
      data-template-id={template}
      data-businessunit-id={BUSINESS_ID}
      data-style-height={height}
      data-theme={theme}
      onClick={onClick}
      className={className}
    >
      <TrustPilotLink size="small" onClick={onClick} theme={theme}>
        Trustpilot
      </TrustPilotLink>
    </div>
  );
};

TrustpilotWidget.propTypes = {
  template: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  locale: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  height: PropTypes.string
};

export default TrustpilotWidget;
