import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ReactModal from 'react-modal';

import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const Modal = ({
  children,
  isOpen,
  size = 'normal',
  className = '',
  overlayClassName = '',
  contentLabel = '',
  onAfterOpen = () => {},
  onRequestClose = () => {},
  ariaHideApp = false,
  shouldCloseOnOverlayClick = true,
  role = 'dialog',
  isRounded = true,
  cornerPosition = '',
  bodyOpenClassName = 'ReactModal__Body--open'
}) => {
  const modalClassName = cx(
    'modal',
    {
      [`modal-${size}`]: size,
      'modal--rounded': isRounded && size !== 'fullscreen',
      'modal--corner-top-left': cornerPosition === 'topLeft',
      'modal--corner-top-right': cornerPosition === 'topRight',
      'modal--corner-bottom-right': cornerPosition === 'bottomRight',
      'modal--corner-bottom-left': cornerPosition === 'bottomLeft',
      'modal--corner-top-left-full': cornerPosition === 'fullTopLeft',
      'modal--corner-top-right-full': cornerPosition === 'fullTopRight',
      'modal--corner-bottom-right-full': cornerPosition === 'fullBottomRight',
      'modal--corner-bottom-left-full': cornerPosition === 'fullBottomLeft'
    },
    className
  );

  return (
    <ReactModal
      bodyOpenClassName={bodyOpenClassName}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={ariaHideApp}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={modalClassName}
      overlayClassName={cx(styles.overlay, overlayClassName)}
      role={role}
      contentLabel={contentLabel}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  ariaHideApp: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  role: PropTypes.string,
  contentLabel: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'big', 'fullscreen']),
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  isRounded: PropTypes.bool,
  cornerPosition: PropTypes.oneOf([
    'topLeft',
    'topRight',
    'bottomLeft',
    'bottomRight',
    'fullTopLeft',
    'fullTopRight',
    'fullBottomRight',
    'fullBottomLeft',
    ''
  ]),
  bodyOpenClassName: PropTypes.string
};

export default Modal;
