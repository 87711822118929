import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import FAQAccordionItem from './FAQAccordionItem';

import styles from './FAQAccordion.module.scss';

const FAQAccordion = ({
  data,
  theme = null,
  openMultiple = false,
  isRedesign = false
}) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const faqAccordionRef = useRef(null);

  const clickHeader = index => {
    faqAccordionRef.current.children[index]
      .querySelector(styles['.faq-accordion-item__top'])
      .click();
  };

  useEffect(() => {
    if (!openMultiple) {
      const initIndex = data.findIndex(faqItem => faqItem.isOpened);
      if (initIndex > -1) {
        setCurrentIndex(initIndex);
        clickHeader(initIndex);
      }
    }
  }, [data, openMultiple]);

  const handleCollpsedChange = (value, index) => {
    if (!openMultiple) {
      if (currentIndex) {
        clickHeader(currentIndex);
      }
      if (!value) {
        setCurrentIndex(index);
        clickHeader(index);
      } else {
        setCurrentIndex(null);
      }
    }
  };

  const containerClasses = classNames(styles['faq-accordion'], {
    [styles['faq-accordion--redesign']]: isRedesign
  });

  return (
    <div className={containerClasses} ref={faqAccordionRef}>
      {data.map((faqItem, index) => (
        <FAQAccordionItem
          key={faqItem.top}
          top={faqItem.top}
          content={faqItem.content}
          isOpened={openMultiple ? faqItem.isOpened : null}
          theme={theme}
          onCollapsedChange={value => handleCollpsedChange(value, index)}
          isRedesign={isRedesign}
        />
      ))}
    </div>
  );
};

FAQAccordion.themes = FAQAccordionItem.themes;

FAQAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      top: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
      isOpened: PropTypes.bool
    })
  ).isRequired,
  theme: PropTypes.oneOf(Object.keys(FAQAccordionItem.themes)),
  openMultiple: PropTypes.bool,
  isRedesign: PropTypes.bool
};

export default FAQAccordion;
