import MediaQuery from 'react-responsive';

export const breakpoints = {
  mobile: { max: 480 },
  phablet: { min: 481, max: 767 },
  tablet: { min: 768, max: 1023 },
  laptop: { min: 1024, max: 1279 },
  desktop: { min: 1280, max: 1439 },
  wide: { min: 1440 }
};

// PRIVATE UTILS
const matchScreen = ({ min = 0, max = 0 }) =>
  typeof window !== 'undefined' &&
  window.matchMedia(
    `(min-width: ${min}px)${max ? ` and (max-width: ${max}px)` : ''}`
  ).matches;

const withMediaQuery =
  ({ min = 0, max = 0 }) =>
  props =>
    (
      <MediaQuery
        {...(min ? { minWidth: min } : {})}
        {...(max ? { maxWidth: max } : {})}
        {...props}
      />
    );

const isBiggerThan = ({ max }) =>
  typeof window !== 'undefined' &&
  window.matchMedia(`(min-width: ${max}px)`).matches;

const isSmallerThan = ({ min }) =>
  typeof window !== 'undefined' &&
  window.matchMedia(`(max-width: ${min}px)`).matches;
// BOOLEAN FUNCTIONS
export const isMobileScreen = () => matchScreen(breakpoints.mobile);
export const isPhabletScreen = () => matchScreen(breakpoints.phablet);
export const isTabletScreen = () => matchScreen(breakpoints.tablet);
export const isLaptopScreen = () => matchScreen(breakpoints.laptop);
export const isDesktopScreen = () => matchScreen(breakpoints.desktop);
export const isWideScreen = () => matchScreen(breakpoints.wide);

export const isBiggerThanMobile = () => isBiggerThan(breakpoints.mobile);
export const isBiggerThanPhablet = () => isBiggerThan(breakpoints.phablet);
export const isBiggerThanTablet = () => isBiggerThan(breakpoints.tablet);
export const isBiggerThanLaptop = () => isBiggerThan(breakpoints.laptop);
export const isBiggerThanDesktop = () => isBiggerThan(breakpoints.desktop);
export const isBiggerThanWide = () => isBiggerThan(breakpoints.wide);

export const isSmallerThanPhablet = () => isSmallerThan(breakpoints.phablet);
export const isSmallerThanTablet = () => isSmallerThan(breakpoints.tablet);
export const isSmallerThanLaptop = () => isSmallerThan(breakpoints.laptop);
export const isSmallerThanDesktop = () => isSmallerThan(breakpoints.desktop);
export const isSmallerThanWide = () => isSmallerThan(breakpoints.wide);

// FROM MQ COMPONENTS
export const FromTablet = withMediaQuery({ min: breakpoints.tablet.min });
export const FromPhablet = withMediaQuery({ min: breakpoints.phablet.min });
export const FromLaptop = withMediaQuery({ min: breakpoints.laptop.min });
export const FromDesktop = withMediaQuery({ min: breakpoints.desktop.min });
export const FromWide = withMediaQuery({ min: breakpoints.wide.min });

// UNTIL MQ COMPONENTS
export const UntilMobile = withMediaQuery({ max: breakpoints.mobile.max });
export const UntilTablet = withMediaQuery({ max: breakpoints.tablet.max });
export const UntilPhablet = withMediaQuery({ max: breakpoints.phablet.max });
export const UntilLaptop = withMediaQuery({ max: breakpoints.laptop.max });
export const UntilDesktop = withMediaQuery({ max: breakpoints.desktop.max });

// ONLY MQ COMPONENTS
export const OnlyMobile = withMediaQuery(breakpoints.mobile);
export const OnlyPhablet = withMediaQuery(breakpoints.phablet);
export const OnlyTablet = withMediaQuery(breakpoints.tablet);
export const OnlyLaptop = withMediaQuery(breakpoints.laptop);
export const OnlyDesktop = withMediaQuery(breakpoints.desktop);
export const OnlyWide = withMediaQuery(breakpoints.wide);

export default breakpoints;
