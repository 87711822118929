import PropTypes from 'prop-types';

const ENABLED_STROKE = '#0D0D0D';
const DISABLED_STROKE = '#C7C7C7';

const ArrowBack = ({ disabled = false }) => {
  const stroke = disabled ? DISABLED_STROKE : ENABLED_STROKE;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99993 1.28577L1.28564 9.00005L8.99993 16.7143"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <line
        x1="1.28564"
        y1="9.21448"
        x2="17.9999"
        y2="9.21448"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

ArrowBack.propTypes = {
  disabled: PropTypes.bool
};

export default ArrowBack;
