/* eslint-disable no-underscore-dangle */
import { clientErrorTracker, trans } from '@spotahome/soyuz/client';

import { ModalOptIn } from '@spotahome/ui-library';

import setMarketingOptIn from '../graphql/mutations/marketing-opt-in';
import getGqlClient from '../graphql/client';

const handleOptInAccepted = async () => {
  try {
    await setMarketingOptIn(getGqlClient())();
  } catch (error) {
    clientErrorTracker.error({
      error,
      source: 'OptInWrapper-handleOptInAccepted'
    });
  }
};

const OptInWrapper = () => (
  <ModalOptIn
    modalBodytext={trans('opt-in-checkbox-question')}
    checkboxText={trans('opt-in-checkbox-text')}
    buttonCtaText={trans('opt-in-button-cta')}
    setMarketingOptIn={handleOptInAccepted}
  />
);

export default OptInWrapper;
