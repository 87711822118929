import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconGeneric = ({ color, size, name, title, className, onClick }) => {
  const varClass = {
    [`icon__${color}`]: color,
    [`icon__${size}`]: size
  };
  const iconClassName = classNames(
    className,
    'icon',
    `icon--${name}`,
    varClass
  );

  if (name === 'twin-beds') {
    return (
      <span>
        <i className="icon icon--single-bed" title={title} />
        <span className="icon--counter">2</span>
      </span>
    );
  }

  if (name === 'angle-left' || name === 'angle-right') {
    return (
      <i
        className={iconClassName}
        title={title}
        onClick={onClick}
        role="button"
        tabIndex="0"
      />
    );
  }

  return <i className={iconClassName} title={title} />;
};

IconGeneric.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

IconGeneric.defaultProps = {
  className: '',
  onClick: () => {}
};

export default IconGeneric;
