import { useEffect, useRef } from 'react';

const PREFIX_SUFFIX_OFFSET = 4;
const INITIAL_PADDING_CSS_VAR = '--initial-padding';
const LEFT_PADDING_CSS_VAR = '--input-padding-left';
const RIGHT_PADDING_CSS_VAR = '--input-padding-right';

const updatePrefixSuffixWidth = (extraWidth, wrapper, cssVar) => {
  const currentSpacing = window
    .getComputedStyle(wrapper)
    .getPropertyValue(cssVar);

  const newWidth =
    parseInt(currentSpacing, 10) + extraWidth + PREFIX_SUFFIX_OFFSET;

  wrapper.style.setProperty(cssVar, `${newWidth}px`);
};

const resetPadding = wrapper => {
  const initialPadding = window
    .getComputedStyle(wrapper)
    .getPropertyValue(INITIAL_PADDING_CSS_VAR);

  wrapper.style.setProperty(LEFT_PADDING_CSS_VAR, initialPadding);
  wrapper.style.setProperty(RIGHT_PADDING_CSS_VAR, initialPadding);
};

export const usePrefixSuffixHandle = deps => {
  const wrapperRef = useRef(null);
  const prefixRef = useRef(null);
  const suffixRef = useRef(null);

  useEffect(() => {
    resetPadding(wrapperRef.current);

    if (prefixRef.current) {
      updatePrefixSuffixWidth(
        prefixRef.current.clientWidth,
        wrapperRef.current,
        LEFT_PADDING_CSS_VAR
      );
    }
    if (suffixRef.current) {
      updatePrefixSuffixWidth(
        suffixRef.current.clientWidth,
        wrapperRef.current,
        RIGHT_PADDING_CSS_VAR
      );
    }
  }, deps);

  return {
    wrapperRef,
    prefixRef,
    suffixRef
  };
};

export default usePrefixSuffixHandle;
