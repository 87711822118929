import Colors from './colors';
import Fonts from './fonts';
import FontWeights from './fontWeights';
import LineHeights from './lineHeights';
import ChatButton from './chatButton';

export default {
  Colors,
  Fonts,
  FontWeights,
  LineHeights,
  ChatButton
};
