import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useCookie } from '../components/utils/withCookie';

const CookiesContext = createContext({
  getCookie: () => {},
  setCookie: () => {},
  removeCookie: () => {},
  getAllCookies: () => {}
});

export const CookiesProvider = ({ children, initialCookie = '' }) => {
  let cookieToUse = initialCookie;
  try {
    cookieToUse = document.cookie;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  const { getCookie, setCookie, removeCookie, getAllCookies } =
    useCookie(cookieToUse);
  return (
    <CookiesContext.Provider
      value={{ getCookie, setCookie, removeCookie, getAllCookies }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

CookiesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  initialCookie: PropTypes.string
};

export const CookiesConsumer = CookiesContext.Consumer;

export const useCookieContext = () => useContext(CookiesContext);

export default { CookiesProvider, CookiesConsumer, useCookieContext };
