const Spotahome = () => (
  <svg
    width="22"
    height="32"
    viewBox="0 0 22 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 0C13.4956 0 16.2088 1.12381 18.2091 3.1242C20.2095 5.12458 21.3333 7.83769 21.3333 10.6667H0C0 7.83769 1.12381 5.12458 3.1242 3.1242C5.12458 1.12381 7.83769 0 10.6667 0"
      fill="#C9E3F7"
    />
    <path
      d="M10.6667 32C7.83769 32 5.12458 30.8762 3.1242 28.8758C1.12381 26.8754 0 24.1623 0 21.3333H21.3333C21.3333 24.1623 20.2095 26.8754 18.2091 28.8758C16.2088 30.8762 13.4956 32 10.6667 32Z"
      fill="#0A0777"
    />
    <path
      d="M10.6667 21.3334C7.83769 21.3334 5.12458 20.2096 3.1242 18.2092C1.12381 16.2088 0 13.4957 0 10.6667H10.6667V21.3334Z"
      fill="#FB6E44"
    />
    <path
      d="M10.6666 10.6667C13.4956 10.6667 16.2087 11.7905 18.2091 13.7909C20.2095 15.7913 21.3333 18.5044 21.3333 21.3334H10.6666V10.6667Z"
      fill="#FFCCEF"
    />
  </svg>
);

export default Spotahome;
