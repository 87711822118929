import styles from '../Footer.module.scss';

import Separator from '../../Separator';

const FooterSeparator = () => (
  <div className={styles.footer__separator}>
    <Separator theme="dark" />
  </div>
);

export default FooterSeparator;
