import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPagePath, trans } from '@spotahome/soyuz/client';
import { Link } from '@spotahome/ui-library';

import ChevronUp from './icons/ChevronUp';

import ChevronDown from './icons/ChevronDowm';

// styles
import './CityList.scss';

const renderLink = (id, name) => {
  const path = getPagePath('marketplace.free-search', {
    params: { locationString: id }
  });

  const linkText = trans('seo.labels.rentals.in', { city: name });

  return <Link href={path}>{linkText}</Link>;
};

const CityList = ({ cities }) => {
  const [isMoreShown, setIsMoreShown] = useState(false);
  const listHeader = useRef(null);

  const toggleShow = () => {
    setIsMoreShown(!isMoreShown);
    if (isMoreShown) {
      listHeader.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const cityListClassNames = classNames('city-list', {
    'city-list--only-first-ten': !isMoreShown
  });

  return (
    <div className="city-list__wrapper">
      <h2 className="city-list__title" ref={listHeader}>
        {trans('landingpage.othercities.title')}
        <span className="city-list__title-number"> ({cities.length})</span>
      </h2>

      <div className={cityListClassNames}>
        {cities.map(city => renderLink(city.id, city.cityName))}
      </div>
      <div
        role="button"
        tabIndex={0}
        className="city-list__show-button"
        onClick={toggleShow}
      >
        {isMoreShown
          ? trans('landingpage.othercities.show-less')
          : trans('landingpage.othercities.show-more')}
        <span className="city-list__show-button-chevron">
          {isMoreShown ? <ChevronUp /> : <ChevronDown />}
        </span>
      </div>
    </div>
  );
};

CityList.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cityName: PropTypes.string
    })
  ).isRequired
};

export default CityList;
