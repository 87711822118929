import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import SpotahomeLineIcon from '../icons/SpotahomeLineIcon';

const getYear = () => new Date().getFullYear();

const CopyrightSimplifiedFooter = ({ className = '', showIcon = true }) => (
  <span className={className}>
    {showIcon && <SpotahomeLineIcon />} ©{getYear()} Spotahome —{' '}
    {trans('footer.label.all_rights_reserved')}
  </span>
);

CopyrightSimplifiedFooter.propTypes = {
  className: PropTypes.string,
  showIcon: PropTypes.bool
};

export default CopyrightSimplifiedFooter;
