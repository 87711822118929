import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import FacebookIcon from '../icons/FacebookIcon';
import Instagram from '../icons/Instagram';
import LinkedIn from '../icons/LinkedIn';
import TwitterIcon from '../icons/TwitterIcon';
import Youtube from '../icons/Youtube';

import TikTokIcon from '../icons/TikTokIcon';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const SOCIAL_LINKS = [
  {
    urlKey: 'footer.social.instagram',
    IconComponent: Instagram
  },
  {
    urlKey: 'footer.social.twitter',
    IconComponent: TwitterIcon
  },
  {
    urlKey: 'footer.social.tiktok',
    IconComponent: TikTokIcon
  },

  {
    urlKey: 'footer.social.linkedin',
    IconComponent: LinkedIn
  },
  {
    urlKey: 'footer.social.facebook',
    IconComponent: FacebookIcon
  },
  {
    urlKey: 'footer.social.youtube',
    IconComponent: Youtube
  }
];

const SocialFooter = ({ linkTheme }) => (
  <FooterBlock>
    <FooterBlockTitle text={trans('footer.label.follow_us')} />
    <FooterBlockList hasSocial>
      {SOCIAL_LINKS.map(({ urlKey, IconComponent }) => (
        <FooterLink
          key={urlKey}
          linkTheme={linkTheme}
          href={trans(urlKey)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconComponent size="big" theme="medium" />
        </FooterLink>
      ))}
    </FooterBlockList>
  </FooterBlock>
);

SocialFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default SocialFooter;
