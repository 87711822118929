import PropTypes from 'prop-types';

import Link from '../Link';

const TrustPilotLink = ({ onClick, theme, size, children }) => (
  <Link
    theme={theme}
    target="_blank"
    rel="noopener noreferrer"
    href="https://www.trustpilot.com/review/www.spotahome.com"
    onClick={onClick}
    underlined={false}
    size={size}
  >
    {children}
  </Link>
);

TrustPilotLink.defaultProps = {
  size: 'medium',
  theme: 'light'
};

TrustPilotLink.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['light', 'dark']),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default TrustPilotLink;
