import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

const withInViewEvent = section => WrappedComponent => {
  return props => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1
    });

    useEffect(() => {
      if (inView) {
        SoyuzAnalytics.sendGA4Event('show', { section });
      }
    }, [inView]);

    return (
      <div ref={ref}>
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withInViewEvent;
