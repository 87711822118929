import landings from './landings';
import marketplace from './marketplace';
import seolandings from './seolandings';
import tenant from './tenant';
import transactional from './transactional';

export default {
  landings,
  marketplace,
  seolandings,
  tenant,
  transactional
};
