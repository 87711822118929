/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Section } from '@spotahome/ui-library';
import { trans } from '@spotahome/soyuz/client';

import classNames from 'classnames';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { MultipleItemsCarousel, CarouselArrows } from './MultipleItemsCarousel';

import PropertyTypeSectionCard from './PropertyTypeSectionCard';

import './PropertyTypeSection.scss';

const NUMBER_OF_ITEM_PER_SWIPE_DESKTOP = 4;
const NUMBER_OF_ITEM_PER_SWIPE_MOBILE = 1;
const NUMBER_OF_ITEM_PER_SWIPE_TABLET = 2;
const PROPERTY_TYPES = ['apartments', 'sharedRoom', 'studio', 'privateRoom'];

const PropertyTypeSection = ({ deviceType, isRedesign = false }) => {
  const [page, setPage] = useState(0);

  const isDesktop = deviceType === 'desktop';
  const isTablet = deviceType === 'tablet';

  const getNumberOfItems = () => {
    if (isDesktop) {
      return NUMBER_OF_ITEM_PER_SWIPE_DESKTOP;
    } else if (isTablet) {
      return NUMBER_OF_ITEM_PER_SWIPE_TABLET;
    }
    return NUMBER_OF_ITEM_PER_SWIPE_MOBILE;
  };

  const getSlidePercentage = () => {
    if (!isRedesign) {
      return 90;
    }

    if (isDesktop) {
      return 100;
    }

    return 80;
  };

  const maxPage = Math.round(PROPERTY_TYPES.length / getNumberOfItems());

  const renderCarouselItem = (type, key) => (
    <PropertyTypeSectionCard type={type} key={key} isRedesign={isRedesign} />
  );

  const sectionClasses = classNames({
    'property-type-section__container--redesign': isRedesign
  });

  const headerClasses = classNames('property-type-section__header', {
    'property-type-section__header--redesign': isRedesign
  });

  const headerTitleClasses = classNames('property-type-section__header-title', {
    'property-type-section__header-title--redesign': isRedesign
  });

  const carouselClasses = classNames('property-type-section__carousel', {
    'property-type-section__carousel--redesign': isRedesign
  });

  return (
    <Section background="light" className={sectionClasses}>
      <div className="property-type-section">
        <div className={headerClasses}>
          <h2 className={headerTitleClasses}>
            {trans('landingpage.label.propertyTypes')}
          </h2>
          {!isDesktop && (
            <span className="property-type-section__arrows">
              <CarouselArrows page={page} setPage={setPage} maxPage={maxPage} />
            </span>
          )}
        </div>
        <div className={carouselClasses}>
          <MultipleItemsCarousel
            items={PROPERTY_TYPES.map(renderCarouselItem)}
            itemsPerSwipe={getNumberOfItems()}
            selectedPage={page}
            isRedesign={isRedesign}
            centerItems={isRedesign && isDesktop}
            slidePercentage={getSlidePercentage()}
          />
        </div>
      </div>
    </Section>
  );
};

PropertyTypeSection.propTypes = {
  deviceType: PropTypes.string.isRequired,
  isRedesign: PropTypes.bool
};

export default withInViewEvent(LANDING_SECTIONS.propertyType)(
  PropertyTypeSection
);
