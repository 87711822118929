import { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Mediator } from '@spotahome/soyuz-mediator';

export const useModalHandle = ({ removeScrollbarCSL = false } = {}) => {
  const [isModalOpen, setOpen] = useState(false);
  const scrollBarWidthRef = useRef(0);
  const handleModalOpen = useCallback(() => setOpen(true), []);
  const handleModalClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    scrollBarWidthRef.current = document.body.offsetWidth;
  }, []);

  useEffect(() => {
    const scrollBarWidth = isModalOpen
      ? document.body.offsetWidth - scrollBarWidthRef.current
      : 0;

    if (removeScrollbarCSL) {
      document.body.style.marginRight = `${scrollBarWidth}px`;
    }
  }, [isModalOpen]);

  return {
    isModalOpen,
    handleModalOpen,
    handleModalClose
  };
};

export const useMediatorModalHandle = ({ channel, onOpen = () => {} }) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();

  const handleMediatorModalOpen = payload => {
    Mediator.publish(channel, payload);
  };

  const handleChannelReceived = payload => {
    onOpen(payload);
    handleModalOpen();
  };

  useEffect(() => {
    Mediator.subscribe(channel, handleChannelReceived);

    return () => Mediator.unsubscribe(channel, handleChannelReceived);
  }, []);

  return {
    isModalOpen,
    handleModalOpen: handleMediatorModalOpen,
    handleModalClose
  };
};

const ComponentWithModal = ({ WrappedComponent, ...props }) => {
  const modalHandle = useModalHandle();
  return <WrappedComponent {...props} {...modalHandle} />;
};

ComponentWithModal.propTypes = {
  WrappedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    .isRequired
};

const withModalHandle = () => WrappedComponent => props =>
  <ComponentWithModal {...props} WrappedComponent={WrappedComponent} />;

export default withModalHandle;
