import PropTypes from 'prop-types';

import Checkbox from '../Checkbox';

import Count from '../Count';

import styles from './CheckboxWithLabel.module.scss';

const CheckboxWithLabel = ({
  className = '',
  text,
  showCount = false,
  count = null,
  checked,
  labelTitle = null,
  ...rest
}) => {
  return (
    <div className={`${styles.checkboxWithLabel} ${className}`}>
      <Checkbox checked={checked} {...rest} />
      {rest.id ? (
        <label htmlFor={rest.id} className={styles.text}>
          {labelTitle && (
            <>
              <span className={styles.title}>{labelTitle}</span>
              <span> - </span>
            </>
          )}
          {text}
        </label>
      ) : (
        <span className={styles.text}>{text}</span>
      )}
      <span className={styles.count}>
        {showCount && !checked && <Count value={count} />}
      </span>
    </div>
  );
};

CheckboxWithLabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  labelTitle: PropTypes.string,
  showCount: PropTypes.bool,
  count: PropTypes.number,
  ...Checkbox.propTypes
};

export default CheckboxWithLabel;
