import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const MenuIcon = ({ theme = 'base', className = '' }) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <title></title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="[Search]-Mobile---List"
        transform="translate(-15.000000, -21.000000)"
        fill={themes[theme]}
        fillRule="nonzero"
        stroke={themes[theme]}
        strokeWidth="0.75"
      >
        <path
          d="M32,23.5540267 L16,23.5540267 L16,22 L32,22 L32,23.5540267 Z M32,30.7770134 L16,30.7770134 L16,29.2229866 L32,29.2229866 L32,30.7770134 Z M32,38 L16,38 L16,36.4459733 L32,36.4459733 L32,38 Z"
          id=""
        />
      </g>
    </g>
  </svg>
);

MenuIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default MenuIcon;
