import { useSoyuzFlag } from '@spotahome/soyuz/client';

import { EcommerceManagerFactory } from './ecommerceManagerFactory';

let ecommerceManagerFactoryInstance;

// Hook to use experiment/flags inside
export const useEcommerceManagerFactory = ({ sendInHouseAnalytics } = {}) => {
  const flag = useSoyuzFlag('SoyuzAnalyticsSalesEvents');
  ecommerceManagerFactoryInstance =
    ecommerceManagerFactoryInstance ||
    EcommerceManagerFactory({
      sendGa4Ecommerce: flag.isOn(),
      sendInHouseAnalytics
    });

  return ecommerceManagerFactoryInstance;
};
