import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';
import { getPagePath, trans } from '@spotahome/soyuz/client';
import { Button } from '@spotahome/ui-library';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import './LandlordSection.scss';

const LandlordSection = () => {
  return (
    <section className="landlord-section">
      <h2 className="landlord-section__title">{trans('home-ll.title')}</h2>
      <div className="landlord-section__text">{trans('home-ll.text')}</div>
      <Button
        color="tertiary"
        className="landlord-section__cta"
        href={getPagePath('externals.landlord-landing')}
      >
        {trans('home-ll.cta')}
      </Button>
    </section>
  );
};

export default withInViewEvent(LANDING_SECTIONS.homeFooter)(LandlordSection);
