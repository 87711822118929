import PropTypes from 'prop-types';

const LocationIcon = ({ color = '#0D0D0D', fill = 'none', ...rest }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.5423 16.4386L10 22.9425L3.45766 16.4386C2.16436 15.1529 1.28413 13.5154 0.927566 11.7333C0.571009 9.95132 0.753978 8.10416 1.45353 6.42526C2.1531 4.74631 3.33807 3.31057 4.85929 2.30011C6.38056 1.28962 8.16955 0.75 10 0.75C11.8305 0.75 13.6194 1.28962 15.1407 2.30011C16.6619 3.31057 17.8469 4.74631 18.5465 6.42526C19.246 8.10416 19.429 9.95133 19.0724 11.7333C18.7159 13.5154 17.8356 15.1529 16.5423 16.4386ZM10 13.5C12.0955 13.5 13.8269 11.8389 13.8269 9.75C13.8269 7.66115 12.0955 6 10 6C7.90446 6 6.17308 7.66115 6.17308 9.75C6.17308 11.8389 7.90446 13.5 10 13.5Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

LocationIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.oneOf(['none', 'black'])
};

export default LocationIcon;
