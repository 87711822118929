import { trans } from '@spotahome/soyuz/client';
import PropTypes from 'prop-types';

import Link from '../Link';

import './CopyWithInterpolatedLink.scss';

const CopyWithInterpolatedLink = ({
  mainCopy,
  linkCopy,
  link,
  onClick = null
}) => {
  const [firstHalfLinkCopy, secondHalfLinkCopy] =
    trans(mainCopy).split('{link}');
  return (
    <div className="copy-with-link">
      {firstHalfLinkCopy}
      <Link href={link} onClick={onClick}>
        {trans(linkCopy)}
      </Link>
      {secondHalfLinkCopy}
    </div>
  );
};

CopyWithInterpolatedLink.propTypes = {
  mainCopy: PropTypes.string.isRequired,
  linkCopy: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default CopyWithInterpolatedLink;
