import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '../Input';
import CheckBoxUnChecked from '../icons/CheckBoxUnChecked';
import CheckBoxChecked from '../icons/CheckBoxChecked';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

class Checkbox extends Component {
  static propTypes = {
    callback: PropTypes.func,
    checked: PropTypes.bool,
    dataAttributes: PropTypes.object,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'normal'])
  };

  static defaultProps = {
    callback: () => {},
    checked: false,
    dataAttributes: {},
    required: false,
    size: 'normal'
  };

  state = {
    checked: this.props.checked || false
  };

  handleCheckbox = () => {
    this.setState(
      prevState => ({
        checked: !prevState.checked
      }),
      () => {
        this.props.callback(this.state.checked);
      }
    );
  };

  render() {
    const closeButtonClassName = cx(styles.checkbox, {
      [styles['checkbox-size-normal']]: this.props.size === 'normal',
      [styles['checkbox-size-small']]: this.props.size === 'small'
    });

    return (
      <div className={styles.wrapper}>
        <Input
          checked={this.state.checked}
          className={closeButtonClassName}
          id={this.props.id}
          name={this.props.name}
          onChange={this.handleCheckbox}
          required={this.props.required}
          dataAttributes={this.props.dataAttributes}
          type="checkbox"
        />

        {this.state.checked ? (
          <CheckBoxChecked size={this.props.size} />
        ) : (
          <CheckBoxUnChecked size={this.props.size} />
        )}
      </div>
    );
  }
}

export default Checkbox;
