import { trans } from '@spotahome/soyuz/client';

import FooterBlockTitle from '../FooterContainer/components/FooterBlockTitle';
import { getLoginImagePath } from '../utils/getExternalImagePath';

import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';
import FooterSimplifiedStoreIcon from './components/FooterSimplifiedStoreIcon';

const IOS_APP_INFO = {
  link: 'https://spotahome.app.link/ios_footer_home',
  eventCategory: 'app-promotion',
  eventAction: 'footer-icon-ios',
  eventLabel: 'open'
};
const ANDROID_APP_INFO = {
  link: 'https://spotahome.app.link/android_footer_home',
  eventCategory: 'app-promotion',
  eventAction: 'footer-icon-android',
  eventLabel: 'open'
};

const AppFooter = () => (
  <>
    <FooterBlockTitle text={trans('footer.label.get_app')} />
    <FooterSimplifiedBlockList isInline>
      <FooterSimplifiedLink href={IOS_APP_INFO.link}>
        <FooterSimplifiedStoreIcon
          src={getLoginImagePath('app-store.png')}
          alt="App Store"
        />
      </FooterSimplifiedLink>
      <FooterSimplifiedLink href={ANDROID_APP_INFO.link}>
        <FooterSimplifiedStoreIcon
          src={getLoginImagePath('google-play.png')}
          alt="Google Play Store"
        />
      </FooterSimplifiedLink>
    </FooterSimplifiedBlockList>
  </>
);

export default AppFooter;
