import PropTypes from 'prop-types';

import styles from './Header/Header.module.scss';

const HeaderItems = ({ children }) => (
  <ul className={styles.nav}>{children}</ul>
);

export default HeaderItems;

HeaderItems.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ])
};

HeaderItems.defaultProps = {
  children: null
};
