import PropTypes from 'prop-types';
import classNames from 'classnames';

const DSIcon = ({ name, title = '', className = '' }) => {
  const iconClassName = classNames(className, `ds-icon-${name}`);

  return <i className={iconClassName} title={title} />;
};

DSIcon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
};

export default DSIcon;
