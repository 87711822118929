import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './Header/Header.module.scss';

const cn = classNames.bind(styles);

const HeaderLink = ({ children, color, className, ...restProps }) => {
  const linkClassname = cn(
    styles.navLink,
    styles[`navLink--${color}`],
    className
  );

  return (
    <a className={linkClassname} {...restProps}>
      {children}
    </a>
  );
};

HeaderLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  color: PropTypes.oneOf(['light', 'dark', 'transparent']),
  className: PropTypes.string
};

HeaderLink.defaultProps = {
  color: 'light',
  className: '',
  children: null
};

export default HeaderLink;
