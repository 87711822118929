import PropTypes from 'prop-types';

import { AVAILABLE_COLORS } from '../utils/colors';
import Close from '../icons/Close';
import IconButton from '../IconButton';

const CloseButton = ({ size = 'small', color = '', ...rest }) => (
  <IconButton {...rest}>
    <Close size={size} theme={color} />
  </IconButton>
);

CloseButton.propTypes = {
  size: PropTypes.oneOf(['normal', 'small', 'extra_small']),
  color: PropTypes.oneOf(AVAILABLE_COLORS),
  ...IconButton.propTypes
};

export default CloseButton;
