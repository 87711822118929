import PropTypes from 'prop-types';

import { getPagePath } from '@spotahome/soyuz/client';

import LoginButton from '../LoginButtonComponent';

import themeShape, { DARK_THEME, LIGHT_THEME } from './shapes/theme.shape';

const HeaderLogin = ({
  color = DARK_THEME,
  isMobile = false,
  newHeaderSignupLogin = false
}) => (
  <LoginButton
    logoutPath={getPagePath('logout')}
    userAreaPath={getPagePath('my-bookings')}
    searchPreferencePath={getPagePath('marketplace.alerts')}
    myProfilePath={getPagePath('my-profile')}
    myReferralsPath={getPagePath('my-referrals')}
    myPaymentsPath={getPagePath('my-payments')}
    color={color}
    isMobile={isMobile}
    newHeaderSignupLogin={newHeaderSignupLogin}
    typeMenu={color === LIGHT_THEME ? 'menu-transparent' : 'menu-white'}
  />
);

HeaderLogin.propTypes = {
  color: themeShape,
  isMobile: PropTypes.bool,
  newHeaderSignupLogin: PropTypes.bool
};

export default HeaderLogin;
