import PropTypes from 'prop-types';

const ENABLED_STROKE = '#0D0D0D';
const DISABLED_STROKE = '#C7C7C7';

const ArrowForward = ({ disabled = false }) => {
  const stroke = disabled ? DISABLED_STROKE : ENABLED_STROKE;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00007 16.7142L16.7144 8.99995L9.00007 1.28566"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <line
        x1="16.7144"
        y1="8.78564"
        x2="6.85803e-05"
        y2="8.78564"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
};

ArrowForward.propTypes = {
  disabled: PropTypes.bool
};

export default ArrowForward;
