import PropTypes from 'prop-types';

import { getColor, AVAILABLE_COLORS } from '../utils/colors';

const SIZES = {
  extra_small: 11,
  small: 14,
  normal: 26
};

const Close = ({ size = 'normal', theme = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SIZES[size]}
    height={SIZES[size]}
    viewBox="0 0 44 44"
  >
    <g fillRule="evenodd" clipRule="evenodd" fill={getColor(theme) || theme}>
      <path d="M38.824,0 L44,5.178 L5.176,44 L0,38.825 L38.824,0z" />
      <path d="M5.176,0 L44,38.825 L38.824,44 L0,5.178 L5.176,0z" />
    </g>
  </svg>
);

Close.propTypes = {
  size: PropTypes.oneOf(['normal', 'small', 'extra_small']),
  theme: PropTypes.oneOf(AVAILABLE_COLORS)
};

export default Close;
