import PropTypes from 'prop-types';

import { useThemeFlag } from '../../../../base/ThemeProvider';

import Link from '../../Link';

const FooterSimplifiedLink = ({ children, ...restProps }) => {
  const newButtonsEnabled = useThemeFlag('newButtons');
  return (
    <li>
      <Link
        theme="beige"
        hover
        size={'medium'}
        underlined={!newButtonsEnabled}
        {...restProps}
      >
        {children}
      </Link>
    </li>
  );
};

FooterSimplifiedLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FooterSimplifiedLink;
