import classNames from 'classnames';
import { trans } from '@spotahome/soyuz/client';
import PropTypes from 'prop-types';

import styles from './HistoricalMetricsRedesign.module.scss';

const EXPERIENCE = [
  {
    title: 'historical.bookings.number',
    text: 'historical.tenants'
  },
  {
    title: 'historical.properties.number',
    text: 'historical.properties'
  },
  {
    title: 'historical.cities.number',
    text: 'historical.cities'
  }
];

const MetricsItem = ({ title, text, numberClassName, textClassName }) => {
  const numberClasses = classNames(
    styles['historical-metrics-redesign__number'],
    styles[numberClassName]
  );

  const textClasses = classNames(
    styles['historical-metrics-redesign__text'],
    styles[textClassName]
  );

  return (
    <div className={styles["historical-metrics-redesign__section"]}>
      <div className={numberClasses}>{trans(title)}</div>
      <div className={textClasses}>{trans(text)}</div>
    </div>
  );
};

MetricsItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  numberClassName: PropTypes.string.isRequired,
  textClassName: PropTypes.string.isRequired
};

const HistoricalMetrics = ({
  className = '',
  numberClassName = '',
  textClassName = ''
}) => {
  const classes = classNames(styles['historical-metrics-redesign'], styles[className]);

  return (
    <div className={classes}>
      <div className={styles["historical-metrics-redesign__content"]}>
        {EXPERIENCE.map(({ title, text }) => (
          <MetricsItem
            title={title}
            text={text}
            key={title}
            numberClassName={numberClassName}
            textClassName={textClassName}
          />
        ))}
      </div>
    </div>
  );
};

HistoricalMetrics.propTypes = {
  className: PropTypes.string,
  numberClassName: PropTypes.string,
  textClassName: PropTypes.string
};

export default HistoricalMetrics;
