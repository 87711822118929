import { PAYMENT_METHODS } from '../../constants';

import { getImagesFolderPath } from '../utils/getExternalImagePath';

import './PaymentMethods.scss';

const PaymentMethods = () => (
  <span className="payment-methods">
    {PAYMENT_METHODS.map(({ filename, name }) => (
      <img key={name} src={getImagesFolderPath(filename)} alt={name} />
    ))}
  </span>
);

export default PaymentMethods;
