import PropTypes from 'prop-types';

import LoggedInDesktopButton from './LoggedInDesktopButton';
import LoggedInMobileButton from './LoggedInMobileButton';

const LoggedIn = ({ isMobile, ...restProps }) =>
  isMobile ? (
    <LoggedInMobileButton {...restProps} />
  ) : (
    <LoggedInDesktopButton {...restProps} />
  );

LoggedIn.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default LoggedIn;
