import PropTypes from 'prop-types';
import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterLink from './components/FooterLink';

import HelpCenterButton from './HelpCenterButton';
import FooterBlockList from './components/FooterBlockList';

const HelpFooter = ({ linkTheme }) => (
  <FooterBlock>
    <FooterBlockTitle text={trans('footer.label.support')} />

    <HelpCenterButton
      color="primary"
      href={getPagePath('externals.help-center')}
    />
    <FooterBlockList>
      <FooterLink href={getPagePath('contact-us')} linkTheme={linkTheme}>
        {trans('footer.item.contact_support')}
      </FooterLink>
    </FooterBlockList>
  </FooterBlock>
);

HelpFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default HelpFooter;
