import { showToast, MapIcon } from '@spotahome/ui-library';
import { trans } from '@spotahome/soyuz/client';

const renderIcon = () => <MapIcon />;
const renderTitle = () => trans('autocompleter.toast.feedback.title');
const renderContent = () => trans('autocompleter.toast.feedback.subtitle');

const CLOSE_AFTER_MS = 8000;

export const showFeedbackToast = () => {
  showToast(
    { renderIcon, renderTitle, renderContent },
    { autoClose: CLOSE_AFTER_MS }
  );
};
