import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Badge.module.scss';

const Badge = ({ label, theme, className, size, ...rest }) => (
  <div
    {...rest}
    className={classNames(
      styles.badge,
      className,
      !!theme && styles[`badge--theme-${theme}`],
      !!size && styles[`badge--size-${size}`]
    )}
  >
    {label}
  </div>
);

Badge.propTypes = {
  label: PropTypes.string,
  theme: PropTypes.oneOf([
    'default',
    'primary',
    'default-light',
    'primary-light'
  ]),
  size: PropTypes.oneOf(['default', 'small', 'big']),
  className: PropTypes.string
};
Badge.defaultProps = {
  label: '',
  theme: 'default',
  size: 'default',
  className: ''
};

export default Badge;
