const HomeKey = () => (
  <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="#B20094"
      fillRule="evenodd"
      d="M1.444 22.555h21.11V9.587L12 1.631 1.444 9.587v12.968zM12.435.147L23.712 8.65c.18.136.288.354.288.578v14.048a.726.726 0 01-.723.725H.723A.726.726 0 010 23.275V9.227c0-.224.107-.442.287-.578L11.565.147a.712.712 0 01.87 0zm-2.85 10.448c-.766 0-1.476.24-2.059.65A3.58 3.58 0 006 14.178a3.58 3.58 0 003.584 3.583 3.586 3.586 0 003.472-2.688h1.905l.896.896.896-.896.896.896.896-.896v-1.791h-5.49a3.586 3.586 0 00-3.47-2.688zm-2.689 3.584a1.793 1.793 0 113.585 0 1.793 1.793 0 01-3.585 0zm2.688 4.947a4.946 4.946 0 004.403-2.688h.41l.496.497a1.364 1.364 0 001.86.063 1.365 1.365 0 001.86-.064l.897-.895c.255-.256.4-.603.4-.965v-1.791c0-.753-.611-1.364-1.365-1.364h-4.558a4.947 4.947 0 10-4.403 7.206zm-.467-4.947a.429.429 0 11-.857 0 .429.429 0 01.857 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default HomeKey;
