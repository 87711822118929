const ClothesDryerOptions = {
  dryer: 'Clothes dryer',
  ind_r_in: 'Drying rack',
  ind_r_terr: 'Drying rack',
  str_back: 'Clotheslines',
  false: 'Clothes dryer'
};

const HeatingOptions = {
  central: 'Central heating',
  s_elec: 'Individual electric units for heating',
  elec_whole: 'Whole house electric heating',
  n_gas: 'Natural gas heating',
  ac: 'AC units for heating',
  other: 'Other heating system',
  false: 'Heating'
};

const HeatingWaterOptions = {
  n_gas: 'Natural gas water heating',
  b_gas: 'Bottled gas water heating',
  elec: 'Electric water heating',
  central: 'Central water heating',
  false: 'Electric water heating' // FIXME: we are reusing a loco key not totally accurate. It should be: "Water Heating".
};

const ACOptions = {
  true: 'A/C',
  central: 'A/C',
  some: 'A/C',
  false: 'A/C'
};

const LaundryWashingMachineOptions = {
  true: 'Washing machine',
  common: 'Washing machine',
  false: 'Washing machine'
};

const LaundryDryerOptions = {
  true: 'Dryer',
  common: 'Dryer',
  false: 'Dryer'
};

const generateBillOptions = label => ({
  true: label,
  na: label,
  false: label
});

const onlyBooleanOptions = label => ({
  true: label,
  false: label
});

export const apartmentFeatures = {
  3000: {
    tracking: 'furnished',
    icon: 'furnished',
    options: onlyBooleanOptions('Furnished'),
    order: 6
  },
  3013: {
    tracking: 'elevator',
    icon: 'elevator',
    options: onlyBooleanOptions('Elevator'),
    order: 11
  },
  3014: {
    tracking: 'heating',
    icon: 'heating',
    options: onlyBooleanOptions('Heating'),
    order: 1
  }, // review heating, we have two options 3027 and 3014
  3002: {
    tracking: 'air',
    icon: 'air_conditioning',
    options: ACOptions,
    order: 2
  },
  3015: {
    tracking: 'kitchen',
    icon: 'equipped_kitchen',
    options: onlyBooleanOptions('Equipped kitchen'),
    order: 4
  },
  3016: {
    tracking: 'balcony',
    icon: 'balcony_terrace',
    options: onlyBooleanOptions('Balcony or terrace'),
    order: 8
  },
  3017: {
    tracking: 'reception',
    icon: 'janitor_reception',
    options: onlyBooleanOptions('Doorman/Reception'),
    order: 14
  },
  3018: {
    tracking: 'pool',
    icon: 'swimming_pool',
    options: onlyBooleanOptions('Pool access'),
    order: 7
  },
  3019: {
    tracking: 'parking',
    icon: 'private_parking',
    options: onlyBooleanOptions('Parking available'),
    order: 12
  },
  3020: {
    tracking: 'pets-allowed',
    icon: 'pets_allowed',
    options: onlyBooleanOptions('Pets allowed'),
    hideInExperiment: true
  },
  3021: {
    tracking: 'smoking-true',
    icon: 'smoking_allowed',
    options: onlyBooleanOptions('Smoking allowed'),
    hideInExperiment: true
  },
  3009: {
    tracking: 'washing-machine',
    icon: 'washing_machine',
    options: LaundryWashingMachineOptions,
    order: 3
  },
  3010: {
    tracking: 'dryer',
    icon: 'dryer',
    options: LaundryDryerOptions,
    order: 10
  },
  3008: {
    tracking: 'dishwasher',
    icon: 'dishwasher',
    options: onlyBooleanOptions('Dishwasher'),
    order: 9
  },
  3100: {
    tracking: 'oven',
    icon: 'oven',
    options: onlyBooleanOptions('Oven'),
    order: 13
  },
  3007: {
    tracking: 'tv',
    icon: 'tv',
    options: onlyBooleanOptions('TV'),
    order: 15
  },
  3011: {
    tracking: 'interior-house',
    icon: 'interior_house',
    options: onlyBooleanOptions('Interior'),
    order: 16
  },
  3012: {
    tracking: 'exterior-house',
    icon: 'exterior_picture',
    options: onlyBooleanOptions('Exterior'),
    order: 16
  },
  3023: {
    tracking: 'handicap-accesible',
    icon: 'accessible',
    options: onlyBooleanOptions('Handicap accessible'),
    order: 17
  },
  3026: {
    tracking: 'water',
    icon: 'central_water_heating',
    options: HeatingWaterOptions,
    order: 18
  },
  3027: {
    tracking: 'heating',
    icon: 'heating',
    options: HeatingOptions,
    order: 1
  },
  3028: {
    tracking: 'dryer',
    icon: 'dryer',
    options: ClothesDryerOptions,
    order: 10,
    hideInExperiment: true
  }
};

export const bedroomFeatures = {
  4000: {
    tracking: 'furnished',
    icon: 'furnished',
    options: onlyBooleanOptions('Furnished'),
    hideInExperiment: true
  },
  4001: {
    tracking: 'air',
    icon: 'air_conditioning',
    options: onlyBooleanOptions('AC unit'),
    order: 3
  },
  4002: {
    tracking: 'heating',
    icon: 'heating',
    options: onlyBooleanOptions('Heating unit'),
    order: 4
  },
  4003: {
    tracking: 'bathroom',
    icon: 'private_bathroom',
    options: onlyBooleanOptions('Private bath (en-suite)'),
    order: 2
  },
  4004: {
    tracking: 'desktop',
    icon: 'desk_table',
    options: onlyBooleanOptions('Desk'),
    order: 8
  },
  4005: {
    tracking: 'balcony',
    icon: 'balcony_terrace',
    options: onlyBooleanOptions('Balcony'),
    order: 7
  },
  4006: {
    tracking: 'tv',
    icon: 'tv',
    options: onlyBooleanOptions('TV'),
    order: 10
  },
  4007: {
    tracking: 'interior-house',
    icon: 'interior_house',
    options: onlyBooleanOptions('Interior'),
    order: 9
  },
  4008: {
    tracking: 'exterior-house',
    icon: 'exterior_picture',
    options: onlyBooleanOptions('Exterior'),
    order: 9
  },
  4009: {
    tracking: 'chest',
    icon: 'drawers',
    options: onlyBooleanOptions('Chest of drawers'),
    order: 11
  },
  4010: {
    tracking: 'shelving',
    icon: 'shelving',
    options: onlyBooleanOptions('Shelving'),
    order: 12
  },
  4100: {
    tracking: 'mini-storage',
    icon: 'package',
    options: onlyBooleanOptions('Storage'),
    order: 13
  },
  4101: {
    tracking: 'home-key',
    icon: 'key_locker',
    options: onlyBooleanOptions('Independent key'),
    order: 6
  },
  4102: {
    tracking: 'shelving',
    icon: 'wardrobe',
    options: onlyBooleanOptions('Built-in wardrobe'),
    order: 14
  },
  4103: {
    tracking: 'shelving',
    icon: 'wardrobe',
    options: onlyBooleanOptions('Standalone wardrobe'),
    order: 14
  },
  4104: {
    tracking: 'window',
    icon: 'window',
    options: onlyBooleanOptions('Window'),
    order: 15
  },
  4105: {
    tracking: 'window-view-street',
    icon: 'window',
    options: onlyBooleanOptions('Window view street'),
    order: 15
  },
  4106: {
    tracking: 'window-view-pedestrian-street',
    icon: 'window',
    options: onlyBooleanOptions('Window view pedestrian street'),
    order: 15
  },
  4107: {
    tracking: 'window-view courtyard',
    icon: 'window',
    options: onlyBooleanOptions('Window view courtyard'),
    order: 15
  },
  4108: {
    tracking: 'window-view-interior-patio',
    icon: 'window',
    options: onlyBooleanOptions('Window view interior patio'),
    order: 15
  },
  4109: {
    tracking: 'couples-allowed',
    icon: 'perfect_for_couples',
    options: {
      true: 'Couples allowed',
      false: 'Couples not allowed'
    },
    order: 5,
    hideInExperiment: true
  }
};

export const billsIncluded = {
  5000: {
    tracking: 'electricity',
    icon: 'electricity_bill',
    options: generateBillOptions('Electricity')
  },
  5001: {
    tracking: 'water',
    icon: 'water_drop',
    options: generateBillOptions('Water')
  },
  5002: {
    tracking: 'gas',
    icon: 'gas_bill',
    options: generateBillOptions('Gas')
  },
  5003: {
    tracking: 'wifi',
    icon: 'wifi',
    options: generateBillOptions('Wifi')
  },
  5004: {
    tracking: 'meal-service',
    icon: 'meal_service',
    options: generateBillOptions('Meal service')
  },
  5005: {
    tracking: 'cleaning-service',
    icon: 'cleaning_service',
    options: generateBillOptions('Cleaning service')
  },
  5006: {
    tracking: 'heating',
    icon: 'heating',
    options: generateBillOptions('Central Heat')
  },
  5007: {
    tracking: 'council-tax',
    icon: 'bank_court_house',
    options: generateBillOptions('Council Tax')
  },
  5008: {
    tracking: 'bed-linen',
    icon: 'bed_linen',
    options: generateBillOptions('Bed Linen')
  }
};

export const roomTypes = {
  6000: {
    icon: 'single_bed',
    options: onlyBooleanOptions('Single Bed'),
    order: 1
  },
  6001: {
    icon: 'double_bed',
    options: onlyBooleanOptions('Double Bed'),
    order: 1
  },
  6002: {
    icon: 'twin_bed',
    options: onlyBooleanOptions('Twin Beds'),
    order: 1
  },
  6003: {
    icon: 'bunk_bed',
    options: onlyBooleanOptions('Bunk Beds'),
    order: 1
  }
};

export const restrictions = {
  1200: {
    icon: 'perfect_for_students',
    options: onlyBooleanOptions('Students')
  },
  1201: {
    icon: 'perfect_for_students',
    options: onlyBooleanOptions('Postgraduate students')
  },
  1202: {
    icon: 'perfect_for_professionals',
    options: onlyBooleanOptions('Professionals')
  }
};

export const suitabilities = {
  1100: { icon: '', options: onlyBooleanOptions('Professionals') },
  1101: { icon: '', options: onlyBooleanOptions('Couples') },
  1102: { icon: '', options: onlyBooleanOptions('Families') },
  1103: { icon: '', options: onlyBooleanOptions('Students') },
  1104: { icon: '', options: onlyBooleanOptions('Erasmus') }
};

export const gender = {
  1000: { icon: '', options: onlyBooleanOptions('Any') },
  1001: { icon: '', options: onlyBooleanOptions('Only males') },
  1002: { icon: '', options: onlyBooleanOptions('Only females') }
};

export const extendedFeatureList = {
  99999: { icon: 'area_m2', tracking: 'm2', name: 'm2' }
};

export default {
  apartmentFeatures,
  bedroomFeatures,
  billsIncluded,
  roomTypes,
  restrictions,
  suitabilities,
  gender,
  extendedFeatureList
};
