import PropTypes from 'prop-types';

import styles from './Header/Header.module.scss';

const HeaderItem = ({ children }) => (
  <li className={styles.navItem}>{children}</li>
);

export default HeaderItem;

HeaderItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ])
};

HeaderItem.defaultProps = {
  children: null
};
