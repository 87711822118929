import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans } from '@spotahome/soyuz/client';

import { MOBILE_APP_BUTTONS } from './utils';
import MobileAppButton from './MobileAppButton';

import styles from './MobileMenu.module.scss';

const cx = classNames.bind(styles);

const MobileMenu = ({
  color = 'dark',
  className = '',
  logo = '',
  children = undefined,
  onClose = () => {},
  onMobileAppButtonClick = () => {}
}) => {
  const mobileMenuClassnames = cx(styles.mobileMenu, styles[color], className);

  const iconClassnames = cx(styles.closeIcon, 'icon');

  const handleClose = () => {
    onClose();
  };

  return (
    <div className={mobileMenuClassnames}>
      <div className={styles.headWrapper}>
        {logo}
        <button
          type="button"
          className={styles.closeButton}
          onClick={handleClose}
        >
          <i className={iconClassnames} />
        </button>
      </div>
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        <strong className={styles.footer_title}>{trans('menu.footer')}</strong>
        <div className={styles.footer_actions}>
          {Object.keys(MOBILE_APP_BUTTONS).map(key => (
            <MobileAppButton
              key={key}
              {...MOBILE_APP_BUTTONS[key]}
              onClick={() => onMobileAppButtonClick(key)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
  logo: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onMobileAppButtonClick: PropTypes.func
};

export default MobileMenu;
