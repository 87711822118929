import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';
import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';
import { getPagePath, trans } from '@spotahome/soyuz/client';

import './DepositProtectionSection.scss';
import CopyWithInterpolatedLink from '@spotahome/ui-library/src/tenant/components/CopyWithInterpolatedLink';

const PUBLIC_ASSETS_LINK =
  'https://sah-public-assets.s3.eu-west-1.amazonaws.com/images/';

const DepositProtectionSection = () => {
  return (
    <div className="deposit-protection" id="deposit-protection">
      <div className="deposit-protection__text-wrapper">
        <div className="deposit-protection__title">
          {trans('home.deposit-protection.title')}
        </div>
        <div className="deposit-protection__text">
          {trans('home.deposit-protection.text-1')}
        </div>
        <div className="deposit-protection__text">
          <CopyWithInterpolatedLink
            link={getPagePath('externals.terms-and-conditions')}
            mainCopy="home.deposit-protection.text-2"
            linkCopy="home.deposit-protection.terms-and-conditions"
          />
        </div>
      </div>
      <img
        src={`${PUBLIC_ASSETS_LINK}DepositProtection.svg`}
        alt={trans('home.deposit-protection.title')}
      />
    </div>
  );
};

export default withInViewEvent(LANDING_SECTIONS.depositProtection)(
  DepositProtectionSection
);
