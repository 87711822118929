import PropTypes from 'prop-types';

const ChevronUp = ({ className }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.42105e-08 5.27423L4 1.71867L8 5.27423L7.10096 6.28564L4 3.52923L0.899038 6.28564L-4.42105e-08 5.27423Z"
        fill="#0D0D0D"
      />
    </svg>
  );
};

export default ChevronUp;
ChevronUp.propTypes = {
  className: PropTypes.string
};

ChevronUp.defaultProps = {
  className: ''
};
