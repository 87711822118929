import PropTypes from 'prop-types';

import { Colors } from '@spotahome/ui-library';

const MagnifyingGlassIcon = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6484 12.502L17 16.6789M12.6484 12.502C11.4136 13.6872 9.70772 14.4203 7.82347 14.4203C4.05497 14.4203 1 11.488 1 7.87079C1 4.2536 4.05497 1.32129 7.82347 1.32129C11.592 1.32129 14.6469 4.2536 14.6469 7.87079C14.6469 9.67939 13.8832 11.3168 12.6484 12.502Z"
      stroke={Colors[color]}
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

MagnifyingGlassIcon.propTypes = {
  color: PropTypes.oneOf(['white', 'primary']),
  width: PropTypes.number,
  height: PropTypes.number
};

MagnifyingGlassIcon.defaultProps = {
  color: 'white',
  width: 19,
  height: 19
};

export default MagnifyingGlassIcon;
