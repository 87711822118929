import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { clientErrorTracker } from '@spotahome/soyuz/client';

import getGqlClient from '../clients/graphql/marketplace';
import getTenantTypeQuery from '../queries/graphql/tenantType';

const TenantTypeContext = createContext();

export const TenantTypeProvider = ({
  tenantType: originalTenantType = null,
  children
}) => {
  const [tenantType, setTenantType] = useState(originalTenantType);

  useEffect(() => {
    const requestTenantType = async () => {
      try {
        const tenantTypeResponse = await getTenantTypeQuery(getGqlClient());
        setTenantType(tenantTypeResponse);
      } catch (error) {
        clientErrorTracker.error({
          error,
          source: 'TenantTypeProvider-requestTenantType'
        });
      }
    };

    if (tenantType === null) {
      requestTenantType();
    }
  }, []);

  return (
    <TenantTypeContext.Provider value={{ tenantType }}>
      {children}
    </TenantTypeContext.Provider>
  );
};

TenantTypeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  tenantType: PropTypes.number
};

export const useTenantTypeContext = () => useContext(TenantTypeContext);
export const TenantTypeConsumer = TenantTypeContext.Consumer;

export default {
  TenantTypeProvider,
  TenantTypeConsumer,
  useTenantTypeContext
};
