import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './DropdownItem.module.scss';

const cx = classNames.bind(styles);

function modifyChildren(child, props) {
  const colorClassName = props.color ? styles[props.color] : '';
  const className = cx(
    'dropdownItem',
    child.props.className,
    props.className,
    colorClassName
  );

  return cloneElement(child, { className });
}

const DropdownItem = props =>
  props.children && modifyChildren(Children.only(props.children), props);

DropdownItem.propTypes = {
  children: PropTypes.element.isRequired,
  color: PropTypes.oneOf(['dark', 'light'])
};

export default DropdownItem;
