import Heading from '../../Heading';
import styles from '../Footer.module.scss';

const FooterBlockTitle = props => (
  <Heading
    level={2}
    theme="dark"
    size="small-medium"
    type="uppercase"
    align="left"
    className={styles.footer__title}
    {...props}
  />
);

export default FooterBlockTitle;
