import { useState } from 'react';

import PropTypes from 'prop-types';
import { trans, useSoyuzRequest } from '@spotahome/soyuz/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useCookieContext } from '../../context/CookiesContext';

import CloseButton from '../CloseButton';
import Spotahome from '../icons/Spotahome';
import Rating from '../Rating';
import Button from '../Button';

import styles from './AppBanner.module.scss';

const COOKIE_ACCEPTED_VALUE = '1';
const COOKIE_EXPIRATION = { days: 1 };
const COOKIE_NAME = 'app-banner-show';
const APP_BANNER_URL = 'https://spotahome.app.link';
const OPEN_LABEL = 'open';
const CLOSE_LABEL = 'close';
const EVENT_CATEGORY = 'app-promotion';

const PROPS_BY_SOURCE = {
  home: {
    eventAction: 'banner-click-homepage',
    path: 'banner_home'
  },
  search: {
    eventAction: 'banner-click-searchpage',
    path: 'banner_search'
  },
  listing: {
    eventAction: 'banner-click-productpage',
    path: 'banner_listing'
  },
  chat: {
    eventAction: 'banner-click-chatpage',
    path: 'banner_chat'
  },
  seo: {
    eventAction: 'banner-click',
    path: 'banner_web'
  }
};

export const AppBanner = ({ source = 'banner_web', deviceType }) => {
  const { sessionID } = useSoyuzRequest();
  const cookieManager = useCookieContext();
  const { eventAction, path } = PROPS_BY_SOURCE[source];
  const openPath = `${APP_BANNER_URL}/${path}?webSessionId=${sessionID}`;

  const [accepted, setAccepted] = useState(
    cookieManager.getCookie(COOKIE_NAME)
  );

  const setAcceptedCookie = () => {
    cookieManager.setCookie(COOKIE_NAME, COOKIE_ACCEPTED_VALUE, {
      maxAge: COOKIE_EXPIRATION
    });
  };

  const handleOpenBanner = e => {
    e.preventDefault();
    SoyuzAnalytics.sendGA4Event('app-banner', {
      category: EVENT_CATEGORY,
      source: eventAction,
      action: OPEN_LABEL
    });
    setAccepted(COOKIE_ACCEPTED_VALUE);
    setAcceptedCookie();
    window.location.href = openPath;
  };

  const handleCloseButton = () => {
    SoyuzAnalytics.sendGA4Event('app-banner', {
      category: EVENT_CATEGORY,
      source: eventAction,
      action: CLOSE_LABEL
    });
    setAccepted(COOKIE_ACCEPTED_VALUE);
    setAcceptedCookie();
  };

  const isMobile = deviceType === 'smartphone';

  if (!isMobile || accepted) {
    return null;
  }

  return (
    <div className={styles['app-banner']}>
      <CloseButton
        size="small"
        className={styles.close}
        onClick={handleCloseButton}
      />
      <a href={openPath} onClick={handleOpenBanner} className={styles.anchor}>
        <div className={styles.logo}>
          <Spotahome />
        </div>
        <div className={styles['text-box']}>
          <div className={styles.title}>{trans('appbanner.title')}</div>
          <div className={styles.rating}>
            <Rating rating={5} size="small" />
          </div>
        </div>
        <Button color="tertiary-light" className={styles.cta}>
          {trans('appbanner.cta')}
        </Button>
      </a>
    </div>
  );
};

AppBanner.propTypes = {
  source: PropTypes.oneOf(Object.keys(PROPS_BY_SOURCE)),
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired
};

export default AppBanner;
