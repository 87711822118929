import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import styles from './Section.module.scss';

const cx = classNames.bind(styles);

const Section = ({
  background,
  backgroundImage,
  backgroundRepeat,
  backgroundSize,
  backgroundPosition,
  minHeight,
  className,
  children,
  id
}) => {
  const SectionClassName = cx(styles.root, styles[background], className);

  let inlineStyles = {};
  if (backgroundImage) {
    inlineStyles = {
      minHeight,
      backgroundImage,
      backgroundRepeat,
      backgroundSize,
      backgroundPosition
    };
  }

  return (
    <section id={id} className={SectionClassName} style={inlineStyles}>
      {children}
    </section>
  );
};
Section.defaultProps = {
  id: '',
  background: 'dark',
  backgroundImage: '',
  backgroundRepeat: '',
  backgroundSize: '',
  backgroundPosition: '',
  minHeight: '',
  className: ''
};
Section.propTypes = {
  id: PropTypes.string,
  background: PropTypes.oneOf(['light', 'dark', 'skin']),
  backgroundImage: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
  minHeight: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
export default Section;
