import { Children, cloneElement, Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './Dropdown.module.scss';

const cx = classNames.bind(styles);

class Dropdown extends Component {
  state = {
    isOpen: false,
    isActive: false
  };

  componentWillUnmount() {
    this.unlistenOutsideClick();
  }

  handleFocus = () => {
    this.setState({
      isActive: true
    });
  };

  handleBlur = () => {
    this.setState({
      isActive: false
    });
  };

  handleToggleDropdown = () => {
    const { isOpen } = this.state;

    if (!isOpen) {
      this.listenOutsideClick();
    } else {
      this.unlistenOutsideClick();
    }

    this.setState({ isOpen: !isOpen });
  };

  handleValidClick = e => {
    if (e.target === e.currentTarget) {
      e.stopPropagation();
    }
  };

  listenOutsideClick = () => {
    window.addEventListener('click', this.collapse, false);
  };

  unlistenOutsideClick = () => {
    window.removeEventListener('click', this.collapse, false);
  };

  collapse = () => {
    if (!this.state.isActive) {
      this.setState({ isOpen: false });
      this.unlistenOutsideClick();
    }
  };

  render() {
    const btnClassName = cx(
      'dropdown',
      {
        active: this.state.isOpen,
        left: this.props.position === 'left'
      },
      this.props.className,
      styles[this.props.color]
    );

    const btnActionClassName = cx(
      'action',
      styles[this.props.textTransform],
      styles[this.props.className]
    );

    return (
      <div
        className={btnClassName}
        onMouseEnter={this.handleToggleDropdown}
        onMouseLeave={this.handleToggleDropdown}
        onClick={this.handleToggleDropdown}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        role="button"
        tabIndex="-1"
        data-test={this.props.dataTest}
      >
        <div className={btnActionClassName}>
          <span>{this.props.title}</span>
          {this.props.arrowIcon ? (
            <div className={styles['icon-container']}>
              <i className="icon icon--angle-down" />
            </div>
          ) : null}
        </div>
        {this.state.isOpen && (
          <div
            role="button"
            tabIndex="-1"
            className={styles.content}
            onClick={this.handleValidClick}
          >
            {Children.map(this.props.children, child =>
              cloneElement(child, { color: this.props.color })
            )}
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  arrowIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  position: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['dark', 'light']),
  textTransform: PropTypes.oneOf(['uppercase', 'capitalize']),
  dataTest: PropTypes.string
};

Dropdown.defaultProps = {
  className: '',
  color: 'dark',
  position: 'left',
  textTransform: 'uppercase',
  arrowIcon: false,
  dataTest: ''
};

export default Dropdown;
