import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { usePrefixSuffixHandle } from './usePrefixSuffixHandle';

import styles from './FormInput.module.scss';

const cx = classNames.bind(styles);

const FormInput = ({
  innerRef = null,
  id = 'form-input',
  title = 'Default',
  name,
  dataTest = 'form-input',
  placeholder = '',
  required = false,
  disabled = false,
  value,
  type = 'text',
  onBlur = () => {},
  onChange = () => {},
  hasError = false,
  min = null,
  max = null,
  rows = 1,
  readOnly = false,
  bold = false,
  renderPrefix = null,
  renderSuffix = null,
  autoFocus = false,
  maxLength = null
}) => {
  const { wrapperRef, prefixRef, suffixRef } = usePrefixSuffixHandle([
    renderPrefix,
    renderSuffix
  ]);

  const isTextArea = type === 'textarea';
  const Element = isTextArea ? 'textarea' : 'input';

  const inputClassnames = cx('form-input', {
    'form-input--with-error': hasError,
    'form-input--disabled': disabled,
    'form-input--read-only': readOnly,
    'form-input--bold': bold
  });

  const titleClassnames = cx('form-input__title', {
    'form-input__title--textarea': isTextArea,
    'form-input__title--with-error': hasError
  });

  const handleOnChange = event => {
    const { value: newValue } = event.target;
    onChange({ value: newValue, name });
  };

  const handleOnBlur = event => {
    const { value: newValue } = event.target;
    onBlur({ value: newValue, name });
  };

  return (
    <div className={styles['form-input__wrapper']} ref={wrapperRef}>
      {renderPrefix && (
        <span className={styles['form-input__prefix']} ref={prefixRef}>
          {renderPrefix()}
        </span>
      )}
      <Element
        className={inputClassnames}
        disabled={disabled}
        ref={innerRef}
        type={type}
        id={id}
        name={name}
        data-test={dataTest}
        required={required}
        onChange={handleOnChange}
        value={value}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        rows={isTextArea ? rows : undefined}
        readOnly={readOnly}
        min={min}
        max={max}
        autoFocus={autoFocus}
        maxLength={maxLength}
      />
      {renderSuffix && (
        <span className={styles['form-input__suffix']} ref={suffixRef}>
          {renderSuffix()}
        </span>
      )}
      <label htmlFor={id} className={titleClassnames}>
        {title.toUpperCase()}
        {required ? '*' : null}
      </label>
    </div>
  );
};

FormInput.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  title: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf([
    'text',
    'textarea',
    'password',
    'email',
    'number',
    'tel'
  ]),
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  readOnly: PropTypes.bool,
  bold: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  renderPrefix: PropTypes.func,
  renderSuffix: PropTypes.func,
  autoFocus: PropTypes.bool
};

export default FormInput;
