import Colors from '../utils/colors';

const SearchHomes = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0)">
      <path
        fill={Colors.primary}
        fillRule="evenodd"
        d="M18.027 9.707a8.32 8.32 0 11-16.64 0 8.32 8.32 0 0116.64 0zm-3.086 8.176a9.661 9.661 0 01-5.234 1.531C4.346 19.414 0 15.068 0 9.707 0 4.346 4.346 0 9.707 0c5.361 0 9.707 4.346 9.707 9.707a9.662 9.662 0 01-1.53 5.234l1.208 1.21a.642.642 0 01.008.008l4.697 4.696c.27.271.27.71 0 .98l-1.961 1.962c-.271.27-.71.27-.98 0l-4.706-4.705a.32.32 0 01-.008-.008l-1.2-1.2zm-.654-10.279l-4.16-3.164a.693.693 0 00-.84 0l-4.16 3.164a.693.693 0 00-.273.552v5.156c0 .383.31.694.693.694h8.32c.383 0 .693-.31.693-.694V8.157a.693.693 0 00-.273-.552zM6.24 12.619V8.5l3.467-2.636L13.174 8.5v4.12H6.24zm11.381 5.983l3.725 3.724.98-.98-3.724-3.725-.98.98zm-1.535-1.536l.555.555.98-.98-.554-.555-.981.98z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="white" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SearchHomes;
