import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import { useSoyuzUser, useSoyuzFlag } from '@spotahome/soyuz/client';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useCookie } from '../../..';
import getGqlClient from '../clients/graphql/marketplace';

import {
  handleSuccessToast,
  handleErrorToast
} from '../components/utils/loginToast';
import { pushToDataLayer } from '../../utils/datalayer';

const UserInfoContext = createContext({
  isUserLogged: false,
  userInfo: null
});

const QUERY_USER_INFO = gql`
  query UserInfo {
    userInfo {
      id
      email
      firstLogin
      fullname
      isActivatedByEmail
      createdAt
      validationActivationSuccess
    }
  }
`;

const getUserInfo = client => () =>
  client.query({
    query: QUERY_USER_INFO,
    fetchPolicy: 'network-only'
  });

const COOKIE_NEW_USER = 'sah-ui-sign-up';

export const UserInfoProvider = ({ children }) => {
  const { id: userId } = useSoyuzUser();
  const [userInfo, setUserInfo] = useState(null);
  const { getCookie, removeCookie } = useCookie();

  const isSoyuzAnalyticsSignupEvent = useSoyuzFlag(
    'SoyuzAnalyticsSignupEvent'
  ).isOn();

  const handleUserLoginEvent = () => {
    const userLoginMethod = getCookie('sah-ui-login');

    if (userLoginMethod) {
      SoyuzAnalytics.sendGA4Event('login', {
        method: userLoginMethod,
        userId
      });
      removeCookie('sah-ui-login');
    }
  };

  const handleUserSignupEvent = () => {
    const userSignUpMethod = getCookie(COOKIE_NEW_USER);

    if (isSoyuzAnalyticsSignupEvent && userInfo && userSignUpMethod) {
      pushToDataLayer({ tenantEmail: userInfo.email });
      SoyuzAnalytics.sendGA4Event('sign_up', { method: userSignUpMethod });
      removeCookie(COOKIE_NEW_USER);
    }
  };

  useEffect(() => {
    handleUserLoginEvent();
    handleUserSignupEvent();
  }, [userInfo]);

  useEffect(() => {
    const requestUserInfo = async () => {
      try {
        const response = await getUserInfo(getGqlClient())();
        setUserInfo(response.data.userInfo);
      } catch (error) {
        console.log(error);
        handleErrorToast(error);
      }
    };

    if (userId) {
      requestUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.id) {
      handleSuccessToast(
        userInfo.firstLogin,
        userInfo.validationActivationSuccess
      );
    }
  }, [userInfo]);

  return (
    <UserInfoContext.Provider
      value={{
        isUserLogged: !!userId,
        userInfo: userInfo || {}
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

UserInfoProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const UserInfoConsumer = UserInfoContext.Consumer;

export const useUserInfoContext = () => useContext(UserInfoContext) || {};

export const useIsUserLogged = () => {
  const userInfoContext = useUserInfoContext();

  return userInfoContext.isUserLogged;
};

export default {
  UserInfoProvider,
  UserInfoConsumer,
  useUserInfoContext,
  useIsUserLogged
};
