export const COMMON_LOGIN_MODAL = 'AUTH_MODAL_OPEN';
export const OPEN_DEPOSIT_PROTECTION_MODAL = 'open_deposit_protection_modal';

export const DELAY_TOAST_IN_MS = 30000;

export const OPEN_MODAL_EVENTS = {
  open: {
    eventCategory: 'signup/login modal',
    eventAction: 'TemporalDiscount',
    eventLabel: 'open'
  },
  close: {
    eventCategory: 'signup/login modal',
    eventAction: 'TemporalDiscount',
    eventLabel: 'close'
  }
};

export const CHANNELS = {
  COMMON_LOGIN_MODAL,
  OPEN_DEPOSIT_PROTECTION_MODAL
};
