import { trans } from '@spotahome/soyuz/client';

const getStructuredFaq = faqs => {
  if (!faqs.length) {
    return null; // No faqs given
  }

  const content = faqs.map(faq => ({
    '@type': 'Question',
    name: faq.top,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.content
    }
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: content
  };
};

const getTenantFaqKeys = () => [
  {
    top: 'home.tenant.FAQ-section.first-item.title',
    content: 'home.tenant.FAQ-section.first-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.second-item.title',
    content: 'home.tenant.FAQ-section.second-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.third-item.title',
    content: 'home.tenant.FAQ-section.third-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.fourth-item.title',
    content: 'home.tenant.FAQ-section.fourth-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.fifth-item.title',
    content: 'home.tenant.FAQ-section.fifth-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.sixth-item.title',
    content: 'home.tenant.FAQ-section.sixth-item.content'
  },
  {
    top: 'home.tenant.FAQ-section.seventh-item.title',
    content: 'home.tenant.FAQ-section.seventh-item.content'
  }
];

export const getTenantFaq = () =>
  getTenantFaqKeys().map(faq => ({
    top: trans(faq.top),
    content: trans(faq.content)
  }));

export const getTenantStructuredFaq = trans => {
  const faq = getTenantFaqKeys().map(faq => ({
    top: trans(faq.top),
    content: trans(faq.content)
  }));

  return getStructuredFaq(faq);
};

export default {
  getTenantFaq,
  getTenantStructuredFaq
};
