import { Mediator } from '@spotahome/soyuz-mediator';

import { loginButtonShape } from '../HeaderContainer/shapes/login.shape';

import { COMMON_LOGIN_MODAL } from '../utils/constants';
import { useUserInfoContext } from '../../context/UserInfoContext';

import { MODAL_SIGNUP, MODAL_LOGIN } from './constants';

import NotLogged from './NotLogged';
import LoggedIn from './LoggedIn';

const triggerOpenModal = (action, eventAction) => {
  Mediator.publish(COMMON_LOGIN_MODAL, {
    initialAction: action,
    events: {
      open: {
        eventCategory: 'signup/login modal',
        eventAction: `${eventAction} button`,
        eventLabel: 'open'
      },
      close: {
        eventCategory: 'signup/login modal',
        eventAction: 'Signup/Login button',
        eventLabel: 'close'
      }
    }
  });
};

const LoginButton = ({
  isMobile = false,
  logoutPath = '#',
  color = 'dark',
  typeMenu = 'menu-transparent',
  userAreaPath = '',
  searchPreferencePath = '',
  myProfilePath = '',
  myReferralsPath = '',
  myPaymentsPath = '',
  newHeaderSignupLogin = false
}) => {
  const { userInfo, isUserLogged } = useUserInfoContext();
  return isUserLogged ? (
    <LoggedIn
      isMobile={isMobile}
      typeMenu={typeMenu}
      userName={userInfo.fullname}
      myProfilePath={myProfilePath}
      myReferralsPath={myReferralsPath}
      myPaymentsPath={myPaymentsPath}
      userAreaPath={userAreaPath}
      searchPreferencePath={searchPreferencePath}
      logoutPath={logoutPath}
    />
  ) : (
    <NotLogged
      isMobile={isMobile}
      color={color}
      typeMenu={typeMenu}
      newHeaderSignupLogin={newHeaderSignupLogin}
      onHandleSignUp={() => triggerOpenModal(MODAL_SIGNUP, 'Signup')}
      onHandleLogIn={() => triggerOpenModal(MODAL_LOGIN, 'Login')}
    />
  );
};

LoginButton.propTypes = {
  ...loginButtonShape
};

export default LoginButton;
