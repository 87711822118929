import gql from 'graphql-tag';

const TENANT_TYPE_QUERY = gql`
  query tenantType {
    tenantType
  }
`;

const getTenantTypeQuery = async client => {
  const { data } = await client.query({
    query: TENANT_TYPE_QUERY,
    fetchPolicy: 'network-only'
  });
  return data?.tenantType;
};

export default getTenantTypeQuery;
