import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPagePath, trans } from '@spotahome/soyuz/client';
import { Link } from '@spotahome/ui-library';

import ChevronUp from './icons/ChevronUp';
import ChevronDown from './icons/ChevronDowm';

import './CityList.scss';

const CityListRedesign = ({ cities }) => {
  const [isMoreShown, setIsMoreShown] = useState(true);
  const listHeader = useRef(null);

  const cityListClassNames = classNames('city-list', {
    'city-list--redesign': !isMoreShown
  });

  const toggleClickHandler = () => {
    setIsMoreShown(!isMoreShown);
    if (isMoreShown) {
      listHeader.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="city-list__wrapper">
      <div
        className="city-list__title-container"
        tabIndex={0}
        role="button"
        onClick={toggleClickHandler}
      >
        <h2 className="city-list__title" ref={listHeader}>
          {trans('landingpage.othercities.title')}
        </h2>
        <div className="city-list__show-button-chevron city-list__show-button-chevron-title">
          {isMoreShown ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>

      {isMoreShown && (
        <>
          <div className={cityListClassNames}>
            {cities.map(city => (
              <Link
                key={city.id}
                href={getPagePath('marketplace.free-search', {
                  params: { locationString: city.id }
                })}
              >
                {city.cityName}
              </Link>
            ))}
          </div>
          <div
            role="button"
            tabIndex={0}
            className="city-list__show-button--mobile"
            onClick={toggleClickHandler}
          >
            {trans('landingpage.othercities.show-less')}
            <span className="city-list__show-button-chevron">
              <ChevronUp />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

CityListRedesign.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cityName: PropTypes.string
    })
  ).isRequired
};

export default CityListRedesign;
