const OptInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="258"
    height="168"
    viewBox="0 0 258 168"
  >
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g transform="translate(-47 -126) translate(47 126)">
          <circle
            cx="210.788"
            cy="137.837"
            r="21.218"
            fill="#002A4D"
            transform="rotate(-45 210.788 137.837)"
          />
          <circle
            cx="209.103"
            cy="132.708"
            r="7.252"
            fill="#FFF"
            transform="rotate(-12.481 209.103 132.708)"
          />
          <circle
            cx="206.165"
            cy="130.835"
            r="2.417"
            fill="#3F3D56"
            transform="rotate(-12.481 206.165 130.835)"
          />
          <path
            fill="#35C2A9"
            d="M209.95 123.492c-8.678-.016-14.77-1.566-14.755-9.427.015-7.862 7.061-14.222 15.739-14.206 8.677.016 15.7 6.402 15.685 14.264-.014 7.861-7.992 9.385-16.67 9.37z"
          />
          <path
            fill="#CCD6DD"
            d="M244.044 141.383c-2.489 8.635-9.756 14.68-17.338 19.814-1.058.717-2.117 1.418-3.176 2.102l-.022.013c-.05.033-.102.065-.15.098l-.653.422.119.053s.117.062-.011.01l-.116-.046c-4.404-1.776-8.887-3.684-12.262-7.011-3.501-3.456-5.547-8.875-3.651-13.415.249-.594.564-1.16.938-1.684.154-.22.32-.43.492-.637 2.13-2.599 5.496-3.846 8.804-3.26 3.309.585 6.043 2.911 7.151 6.083.557-5.186-3.719-9.53-7.834-12.735-4.117-3.203-8.794-6.692-9.59-11.849-.445-2.87.553-5.65 2.37-7.882.057-.068.113-.136.17-.201 2.213-2.6 5.325-4.27 8.715-4.675 6.312-.656 12.444 2.685 17.03 7.074 7.381 7.062 11.844 17.909 9.014 27.726z"
          />
          <path
            fill="#FFF"
            d="M232.179 130.09c1.211 1.884 2.141 3.936 2.76 6.089.525 1.925.758 3.917.692 5.91-.169 4.103-1.32 8.105-3.358 11.67-1.534 2.703-3.405 5.203-5.567 7.438-1.058.717-2.117 1.418-3.176 2.102l-.022.013c-.05.033-.102.065-.15.098l-.653.422s.236.115.108.063l-.116-.046c.8-6.567-1.633-13.114-6.528-17.564-2.418-2.16-5.312-3.717-8.447-4.546.154-.22.32-.43.492-.637 1.21.34 2.389.785 3.521 1.332 4.047 1.943 7.36 5.138 9.45 9.111 1.876 3.608 2.683 7.676 2.327 11.726.18-.164.362-.331.538-.497 3.346-3.12 6.319-6.702 8.33-10.835 1.746-3.5 2.604-7.376 2.498-11.286-.164-4.263-1.862-8.212-4.322-11.65-2.695-3.667-5.88-6.949-9.464-9.753-3.613-2.894-7.566-5.334-11.771-7.267-.197-.092-.287-.323-.205-.524.028-.087.089-.16.17-.201.084-.037.181-.033.262.01.52.24 1.035.482 1.547.735 4.26 2.105 8.242 4.732 11.854 7.82 3.49 2.975 6.775 6.374 9.23 10.266z"
          />
          <ellipse
            cx="36.317"
            cy="163.3"
            fill="#002A4D"
            rx="5.372"
            ry="2.014"
          />
          <ellipse
            cx="49.208"
            cy="163.032"
            fill="#002A4D"
            rx="5.372"
            ry="2.014"
          />
          <ellipse
            cx="64.66"
            cy="128.303"
            fill="#002A4D"
            rx="10.609"
            ry="3.33"
            transform="rotate(-29.963 64.66 128.303)"
          />
          <circle
            cx="40.614"
            cy="134.965"
            r="21.218"
            fill="#002A4D"
            transform="rotate(-80.783 40.614 134.965)"
          />
          <path
            fill="#002A4D"
            d="M30.945 151.617H37.391V163.166H30.945zM43.837 151.617H50.283V163.166H43.837z"
          />
          <circle cx="41.151" cy="129.593" r="7.252" fill="#FFF" />
          <circle cx="41.151" cy="129.593" r="2.417" fill="#3F3D56" />
          <path
            fill="#CCD6DD"
            d="M20.032 115.233c-1.713-7.673 3.763-15.426 12.232-17.317s16.723 2.796 18.437 10.469c1.713 7.672-3.9 10.502-12.369 12.393s-16.587 2.127-18.3-5.545z"
          />
          <ellipse
            cx="16.715"
            cy="125.12"
            fill="#002A4D"
            rx="3.33"
            ry="10.609"
            transform="rotate(-64.626 16.715 125.12)"
          />
          <path
            fill="#FFF"
            d="M30.192 142.62c0 2.076 5.347 6.177 11.28 6.177 5.933 0 11.497-5.847 11.497-7.923 0-2.077-5.564.402-11.497.402s-11.28-.733-11.28 1.343z"
          />
          <ellipse
            cx="114.599"
            cy="163.3"
            fill="#002A4D"
            rx="5.372"
            ry="2.014"
          />
          <ellipse
            cx="101.707"
            cy="163.032"
            fill="#002A4D"
            rx="5.372"
            ry="2.014"
          />
          <ellipse
            cx="86.256"
            cy="128.303"
            fill="#002A4D"
            rx="3.33"
            ry="10.609"
            transform="rotate(-60.037 86.256 128.303)"
          />
          <circle cx="110.302" cy="134.965" r="21.218" fill="#002A4D" />
          <path
            fill="#002A4D"
            d="M113.525 151.617H119.971V163.166H113.525zM100.633 151.617H107.079V163.166H100.633z"
          />
          <circle cx="105.331" cy="129.593" r="7.252" fill="#FFF" />
          <ellipse
            cx="101.882"
            cy="128.608"
            fill="#3F3D56"
            rx="2.424"
            ry="2.407"
            transform="rotate(-44.974 101.882 128.608)"
          />
          <path
            fill="#35C2A9"
            d="M112.583 120.778c-8.468-1.89-14.081-4.72-12.368-12.393 1.713-7.673 9.968-12.36 18.437-10.469 8.469 1.891 13.945 9.644 12.232 17.317-1.713 7.672-9.832 7.436-18.3 5.545z"
          />
          <ellipse
            cx="129.766"
            cy="140.885"
            fill="#002A4D"
            rx="3.33"
            ry="10.609"
            transform="rotate(-14.875 129.766 140.885)"
          />
          <path
            fill="#FFF"
            d="M105.495 141.277c-3.896 0-7.548-2.48-7.548-.403s3.652 7.923 7.548 7.923c3.895 0 7.406-4.1 7.406-6.177s-3.51-1.343-7.406-1.343z"
          />
          <path
            fill="#35C2A9"
            d="M78.074 99.785H76.19c-3.515-.004-6.363-2.852-6.367-6.367v-1.582c0-3.918.742-7.199 2.207-9.751 1.774-2.969 3.844-5.75 6.179-8.302 1.55-1.559 2.956-3.254 4.201-5.064.894-1.407 1.355-3.045 1.327-4.712 0-3.93-2.56-5.84-7.829-5.84-2.922.032-5.826.468-8.629 1.294-2.192.626-4.55.187-6.37-1.186-1.819-1.373-2.888-3.52-2.888-5.799v-3.202c-.013-2.817 1.634-5.376 4.203-6.531 4.95-2.113 10.29-3.163 15.672-3.083 5.638 0 10.728.947 15.128 2.814 4.409 1.872 7.892 4.512 10.352 7.846 2.453 3.31 3.757 7.331 3.713 11.451 0 4.424-1.208 8.278-3.59 11.453-2.356 3.143-5.734 6.584-10.039 10.228-2.268 1.886-4.417 3.911-6.436 6.063-1.303 1.404-2.225 3.12-2.675 4.982-.52 3.059-3.172 5.295-6.275 5.288zM77.026 129.725h-.248c-6.514-.004-11.792-5.286-11.792-11.8 0-6.514 5.278-11.796 11.792-11.8h.248c4.218-.003 8.116 2.246 10.226 5.898 2.11 3.652 2.11 8.152 0 11.804-2.11 3.652-6.008 5.9-10.226 5.898z"
          />
          <path
            fill="#657786"
            fillOpacity=".6"
            d="M153.713 165.537H.493c-.272 0-.493-.22-.493-.493 0-.272.22-.493.493-.493h153.22c.272 0 .492.22.492.493 0 .272-.22.493-.492.493zM257.173 165.537H175.39c-.272 0-.493-.22-.493-.493 0-.272.22-.493.493-.493h81.783c.272 0 .493.22.493.493 0 .272-.22.493-.493.493z"
          />
          <ellipse
            cx="206.349"
            cy="147.816"
            fill="#002A4D"
            rx="10.609"
            ry="3.33"
            transform="rotate(-1.12 206.349 147.816)"
          />
          <path
            fill="#F2F2F2"
            d="M201.088 73.408h-12.81c-1.359-.002-2.46-1.104-2.462-2.464v-12.81c.001-1.359 1.103-2.46 2.463-2.462h12.81c1.36.001 2.461 1.103 2.463 2.463v12.81c-.002 1.36-1.104 2.461-2.464 2.463zm-12.81-16.751c-.815 0-1.476.662-1.477 1.478v12.81c0 .815.662 1.476 1.478 1.477h12.81c.815 0 1.476-.662 1.477-1.478v-12.81c0-.815-.662-1.476-1.478-1.477h-12.81z"
          />
          <path
            fill="#F2F2F2"
            d="M207 65.032h-12.809c-1.36-.001-2.462-1.103-2.463-2.463v-12.81c.001-1.36 1.103-2.461 2.463-2.463h12.81c1.36.002 2.461 1.104 2.463 2.464v12.809c-.002 1.36-1.104 2.462-2.464 2.463zm-12.809-16.75c-.816 0-1.477.662-1.478 1.478v12.809c0 .816.662 1.477 1.478 1.478h12.81c.815-.001 1.476-.662 1.477-1.478v-12.81c0-.815-.662-1.477-1.478-1.477h-12.809zM106.003 24.14h-12.81c-1.359 0-2.46-1.103-2.462-2.463V8.868c.001-1.36 1.103-2.462 2.463-2.463h12.81c1.36.001 2.461 1.103 2.463 2.463v12.81c-.002 1.36-1.104 2.461-2.464 2.463zM93.193 7.39c-.815 0-1.476.662-1.477 1.478v12.81c0 .815.662 1.477 1.478 1.477h12.81c.815 0 1.476-.662 1.477-1.478V8.868c0-.816-.662-1.477-1.478-1.478h-12.81z"
          />
          <path
            fill="#F2F2F2"
            d="M98.12 17.736H85.312c-1.36-.001-2.462-1.103-2.463-2.463V2.463C82.849 1.103 83.95.002 85.31 0h12.81c1.36.002 2.461 1.104 2.463 2.463v12.81c-.002 1.36-1.104 2.462-2.463 2.463zM85.312.986c-.816 0-1.477.661-1.478 1.477v12.81c.001.816.662 1.477 1.478 1.478h12.81c.815-.001 1.477-.662 1.478-1.478V2.463c-.001-.816-.663-1.477-1.478-1.478H85.31z"
          />
          <circle
            cx="158.255"
            cy="90.242"
            r="3.186"
            fill="#CCD6DD"
            fillOpacity=".2"
          />
          <circle
            cx="30.161"
            cy="78.911"
            r="3.186"
            fill="#CCD6DD"
            fillOpacity=".2"
          />
          <circle cx="244.965" cy="106.5" r="3.186" fill="#F2F2F2" />
        </g>
      </g>
    </g>
  </svg>
);

export default OptInIcon;
