import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const SIZES = {
  small: {
    width: '9px',
    height: '19px'
  },
  base: {
    width: '13px',
    height: '24px'
  }
};

const BackArrow = ({
  color = Colors.dark,
  dataTest = undefined,
  size = 'base'
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SIZES[size].width}
    height={SIZES[size].height}
    viewBox="0 0 10 20"
    data-test={dataTest}
  >
    <path
      fill={color}
      d="M7.783 19.333a1.332 1.332 0 01-1.04-.493l-6.44-8a1.334 1.334 0 010-1.693l6.667-8a1.335 1.335 0 112.053 1.706L3.063 10l5.76 7.147a1.334 1.334 0 01-1.04 2.186z"
    />
  </svg>
);

BackArrow.propTypes = {
  color: PropTypes.string,
  dataTest: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES))
};

export default BackArrow;
