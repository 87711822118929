import PropTypes from 'prop-types';

import styles from '../LoginButton/LoginButton.module.scss';

import MobileLink from './MobileLink';

const NotLoggedMobileButton = props => (
  <span className={styles['login-button__login-signup__links']}>
    <MobileLink
      onClick={props.onHandleSignUp}
      trans="signup.text"
      className="ga-signup-menu-button"
      dataTest="signup-hamburger-link"
    />
    <span className={styles[props.color]}>{' | '}</span>
    <MobileLink
      onClick={props.onHandleLogIn}
      trans="login.text"
      className="ga-login-menu-button"
      dataTest="login-hamburger-link"
    />
  </span>
);

NotLoggedMobileButton.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']).isRequired,
  onHandleSignUp: PropTypes.func.isRequired,
  onHandleLogIn: PropTypes.func.isRequired
};

export default NotLoggedMobileButton;
