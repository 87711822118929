import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CollapsibleDown from '../icons/CollapsibleDown';

import CollapsibleUp from '../icons/CollapsibleUp';

import styles from './Collapsible.module.scss';

const Collapsible = ({
  renderHeader,
  renderBody,
  isCollapsed = false,
  isControlled = true,
  lazyLoad = false,
  className = null,
  inlineTitle = false,
  dataTest = null,
  hasIcon = false,
  onCollapsedChange = () => {},
  headerClassName = null,
  bodyClassName = null,
  isRounded = false,
  iconClosed = null,
  iconOpened = null,
  iconTheme = 'dark'
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const [height, setHeight] = useState(isCollapsed ? '0px' : 'auto');
  const [shouldRenderBody, setShouldRenderBody] = useState(!lazyLoad);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const collapsibleBodyref = useRef();

  const getContentHeight = () => {
    const content = collapsibleBodyref.current;
    const contentStyles = window.getComputedStyle(content);
    const absoluteContentHeight =
      parseInt(contentStyles.height, 10) +
      parseInt(contentStyles.marginTop, 10) +
      parseInt(contentStyles.marginBottom, 10);

    return `${absoluteContentHeight}px`;
  };

  const updateHeight = () => {
    const newHeight = getContentHeight();
    if (!collapsed && height !== newHeight) {
      setHeight(newHeight);
    }
  };

  const handleClose = () => {
    setCollapsed(true);
    setHeight(0);
  };

  const handleOpen = () => {
    setCollapsed(false);
    setHeight(getContentHeight());
    setShouldRenderBody(true);
    setIsTransitioning(true);
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  useEffect(() => {
    if (!collapsed) {
      updateHeight();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    if (isCollapsed !== collapsed && isControlled) {
      if (isCollapsed) {
        handleClose();
      } else {
        handleOpen();
      }
    }
  }, [isCollapsed]);

  const handleClick = e => {
    e.preventDefault();
    if (collapsed) {
      handleOpen();
      onCollapsedChange(false);
    } else {
      handleClose();
      onCollapsedChange(true);
    }
  };

  const collapsibleClassNames = classNames(className, {
    'collapsible--collapsed': collapsed,
    'collapsible--inline': inlineTitle
  });

  const headerClassNames = classNames(
    styles.collapsible__header,
    headerClassName,
    {
      [styles['collapsible__header--rounded']]: isRounded,
      [styles['collapsible__header--rounded-open']]: isRounded && collapsed
    }
  );

  const wrapperStyles = { minHeight: height };
  const wrapperClassNames = classNames(styles['collapsible__content-wrapper'], {
    [styles['collapsible__content-wrapper--opened']]:
      !collapsed && !isTransitioning,
    [styles['collapsible__content-wrapper--rounded']]: isRounded && !collapsed
  });

  const bodyClassNames = classNames(bodyClassName, {
    [styles['collapsible__content-body--rounded']]: isRounded
  });

  const DownIcon = iconClosed || <CollapsibleDown theme={iconTheme} />;
  const UpIcon = iconOpened || <CollapsibleUp theme={iconTheme} />;

  return (
    <div className={collapsibleClassNames}>
      <div
        className={headerClassNames}
        onClick={handleClick}
        role="presentation"
        data-test={dataTest}
      >
        {renderHeader(collapsed)}
        {hasIcon && (collapsed ? DownIcon : UpIcon)}
      </div>
      <div
        data-testid="collapsible-content-wrapper"
        style={wrapperStyles}
        className={wrapperClassNames}
        onTransitionEnd={handleTransitionEnd}
      >
        <div
          data-testid="collapsible-content-body"
          className={bodyClassNames}
          ref={collapsibleBodyref}
        >
          {shouldRenderBody ? renderBody(collapsed) : null}
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  renderHeader: PropTypes.func.isRequired,
  renderBody: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  onCollapsedChange: PropTypes.func,
  inlineTitle: PropTypes.bool,
  bodyClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  isRounded: PropTypes.bool,
  iconTheme: PropTypes.string,
  isControlled: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  dataTest: PropTypes.string,
  iconClosed: PropTypes.node,
  iconOpened: PropTypes.node
};

export default Collapsible;
