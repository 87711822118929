import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 12,
    height: 12
  },
  normal: {
    width: 16,
    height: 16
  },
  big: {
    width: 20,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark,
  medium: Colors.medium
};

const LinkedIn = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 23 22"
    className={className}
    aria-label="new-icon"
  >
    <path
      d="M20.4514 0.735352C21.2492 0.735352 21.9531 1.4393 21.9531 2.28404V20.2582C21.9531 21.1029 21.2492 21.7599 20.4514 21.7599H2.38338C1.58558 21.7599 0.928558 21.1029 0.928558 20.2582V2.28404C0.928558 1.4393 1.58558 0.735352 2.38338 0.735352H20.4514ZM7.26408 18.7564V8.76035H4.16672V18.7564H7.26408ZM5.7154 7.35245C6.70092 7.35245 7.49873 6.55465 7.49873 5.56912C7.49873 4.5836 6.70092 3.73886 5.7154 3.73886C4.68294 3.73886 3.88514 4.5836 3.88514 5.56912C3.88514 6.55465 4.68294 7.35245 5.7154 7.35245ZM18.9496 18.7564V13.2656C18.9496 10.5906 18.3395 8.47877 15.1952 8.47877C13.6935 8.47877 12.661 9.32351 12.2386 10.1213H12.1917V8.76035H9.23514V18.7564H12.3325V13.8288C12.3325 12.5147 12.5672 11.2476 14.2097 11.2476C15.8053 11.2476 15.8053 12.7494 15.8053 13.8757V18.7564H18.9496Z"
      fill={THEMES[theme]}
    />
  </svg>
);

LinkedIn.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'medium'])
};

export default LinkedIn;
