import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuIconMobile from '../icons/MenuIcon';

import styles from './MenuIconMobile.module.scss';

const cx = classNames.bind(styles);

const MenuIcon = ({
  color = 'dark',
  className = '',
  onMenuClick = () => {}
}) => {
  const menuIconClassname = cx(styles.menuIcon, styles[color], className);

  return (
    <button
      type="button"
      className={menuIconClassname}
      onClick={onMenuClick}
      data-test="hamburger-menu"
    >
      <MenuIconMobile theme={color} className={className} />
    </button>
  );
};

MenuIcon.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string,
  onMenuClick: PropTypes.func
};

export default MenuIcon;
