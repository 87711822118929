import PropTypes from 'prop-types';

import { useThemeFlag } from '../../../../base/ThemeProvider';

import Link from '../../Link';

const FooterLink = ({ children, linkTheme, ...restProps }) => {
  const newButtonsEnabled = useThemeFlag('newButtons');
  return (
    <li>
      <Link
        theme={linkTheme}
        hover
        size={'medium'}
        underlined={!newButtonsEnabled}
        {...restProps}
      >
        {children}
      </Link>
    </li>
  );
};

FooterLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  linkTheme: PropTypes.string.isRequired
};

export default FooterLink;
