import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

const mapDataAttributes = dataAttributes =>
  Object.keys(dataAttributes).reduce((acc, key) => {
    acc[`data-${key}`] = dataAttributes[key];
    return acc;
  }, {});

const Input = forwardRef((props, ref) => {
  const {
    autoComplete,
    dataAttributes,
    dataTest,
    checked,
    pattern,
    type,
    ...inputProps
  } = props;

  if (type === 'checkbox') {
    inputProps.checked = checked;
  } else if (type === 'email' || type === 'password') {
    inputProps.pattern = pattern;
  }

  const mapped = mapDataAttributes(dataAttributes);

  return (
    <input
      {...inputProps}
      {...mapped}
      autoComplete={autoComplete ? autoComplete.toString() : undefined}
      ref={ref}
      type={type}
      data-test={dataAttributes.test || dataTest}
    />
  );
});

Input.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  checked: PropTypes.bool,
  className: PropTypes.string,
  dataAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  dataTest: PropTypes.string
};

Input.defaultProps = {
  autoComplete: false,
  checked: false,
  className: undefined,
  dataAttributes: {},
  disabled: false,
  id: undefined,
  onChange: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  onClick: () => {},
  pattern: undefined,
  placeholder: undefined,
  readOnly: false,
  required: false,
  type: 'text',
  value: undefined,
  dataTest: ''
};

const withDefaultStyles = WrappedInput => props => {
  const { className, disabled, size, type, width } = props;

  const cn = cx(
    'input',
    {
      disabled
    },
    styles[type],
    styles[size],
    styles[width],
    className
  );

  return <WrappedInput {...props} className={cn} />;
};

const StyledInput = withDefaultStyles(Input);

StyledInput.propTypes = {
  ...Input.propTypes,
  size: PropTypes.oneOf(['normal', 'small', 'big']),
  width: PropTypes.oneOf(['full'])
};

StyledInput.defaultProps = {
  ...Input.defaultProps,
  size: 'small',
  width: 'full'
};

export { Input };
export default StyledInput;
