import moment from 'moment';

import { getRentalTypesDisplay } from './rentalType';

const MOMENT_INCLUSIVE_COMPARISON = '[]';

const dateIsBetween = (momentDate, initialRangeDate, endRangeDate) =>
  momentDate.isBetween(
    initialRangeDate,
    endRangeDate,
    undefined,
    MOMENT_INCLUSIVE_COMPARISON
  );

export const getMonths = (from, to, format = 'MM') => {
  const startDate = moment(from);
  const endDate = moment(to);

  const monthsArr = [startDate.locale('en').format(format)];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate, 'month')) {
    const date = currentDate.add(1, 'month');
    monthsArr.push(date.format(format));
    currentDate = date;
  }

  return monthsArr;
};

export const getFlexibleRange = (
  { moveIn, moveOut },
  { flexibleMoveIn = false, flexibleMoveOut = false } = {}
) => {
  return {
    moveIn: flexibleMoveIn
      ? moment(moveIn).subtract(15, 'days').format('YYYY-MM-DD')
      : moveIn,
    moveOut: flexibleMoveOut
      ? moment(moveOut).add(15, 'days').format('YYYY-MM-DD')
      : moveOut
  };
};

export const getMonthsStaying = (
  { moveIn, moveOut },
  { flexibleMoveIn = false, flexibleMoveOut = false } = {}
) => {
  if (!moveIn || !moveOut) {
    return undefined;
  }
  const flexibleRange = getFlexibleRange(
    { moveIn, moveOut },
    { flexibleMoveIn, flexibleMoveOut }
  );
  return getMonths(flexibleRange.moveIn, flexibleRange.moveOut, 'MMM').map(
    month => month.toLowerCase()
  );
};

export const getUserAge = birthDate => {
  return moment().diff(birthDate, 'years');
};

export const getDaysDiff = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  return endDate.diff(startDate, 'days');
};

export const checkIfAvailable = (moveIn, moveOut, unavailabilities) => {
  const startDate = moment(moveIn);
  const endDate = moment(moveOut);

  return !unavailabilities.some(u => {
    const fromU = moment(u.from);
    const toU = moment(u.to);

    return (
      dateIsBetween(startDate, u.from, u.to) ||
      dateIsBetween(endDate, u.from, u.to) ||
      dateIsBetween(fromU, startDate, endDate) ||
      dateIsBetween(toU, startDate, endDate)
    );
  });
};

export const formatDate = date => moment(date).format(`ll`);

export const formatReadableDateRange = (moveIn, moveOut) => {
  if (!moveIn && !moveOut) {
    return null;
  }
  if (moveIn && !moveOut) {
    return `${formatDate(moveIn)}`;
  }
  if (!moveIn && moveOut) {
    return `${formatDate(moveOut)}`;
  }
  return `${formatDate(moveIn)} - ${formatDate(moveOut)}`;
};

export const formatReadableDateWithRentalType = (moveIn, rentalTypes) => {
  if (!moveIn && !rentalTypes) {
    return null;
  }

  if (moveIn && rentalTypes.length === 0) {
    return `${formatDate(moveIn)}`;
  }

  if (!moveIn && rentalTypes) {
    return getRentalTypesDisplay(rentalTypes);
  }

  return `${formatDate(moveIn)} - ${getRentalTypesDisplay(rentalTypes)}`;
};
