import PropTypes from 'prop-types';

import Badge from '../Badge';

const IS_ALT = true;

const SIZES_CORRELATION = {
  default: 'small',
  normal: 'small',
  large: 'default',
  big: 'default'
};

const COLOR_CORRELATION = {
  primary: {
    [IS_ALT]: 'primary',
    [!IS_ALT]: 'primary-light'
  },
  select: {
    [IS_ALT]: 'select',
    [!IS_ALT]: 'select-light'
  },
  help: {
    [IS_ALT]: 'default',
    [!IS_ALT]: 'default-light'
  },
  default: {
    [IS_ALT]: 'default'
  }
};

const getSize = size => SIZES_CORRELATION[size] || SIZES_CORRELATION.default;
const getTheme = (color, isAlt) =>
  COLOR_CORRELATION[color]
    ? COLOR_CORRELATION[color][isAlt]
    : COLOR_CORRELATION.primary[IS_ALT];

const ButtonBadge = ({ alt, badge, color, size }) => {
  const badgeProps =
    typeof badge === 'string' ? { label: badge } : { ...badge };

  return (
    <Badge size={getSize(size)} theme={getTheme(color, alt)} {...badgeProps} />
  );
};

ButtonBadge.propTypes = {
  alt: PropTypes.bool,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(Badge.propTypes)
  ]),
  size: PropTypes.string,
  color: PropTypes.string
};

ButtonBadge.defaultProps = {
  color: 'default',
  size: 'default',
  alt: false,
  badge: ''
};

export default ButtonBadge;
