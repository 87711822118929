import gql from 'graphql-tag';

const MUTATION_CREATE_TICKET_ZENDESK = gql`
  mutation createZendeskTicket($input: CreateSupportTicketInput!) {
    createSupportTicket(input: $input) {
      id
      status
    }
  }
`;

const MUTATION_CREATE_TICKET_ZENDESK_LOGGED = gql`
  mutation createZendeskTicketLogged($input: CreateSupportTicketLoggedInput!) {
    createSupportTicketLogged(input: $input) {
      id
      status
    }
  }
`;

const createZendeskTicket =
  client =>
  ({ input }) =>
    client.mutate({
      mutation: MUTATION_CREATE_TICKET_ZENDESK,
      variables: { input }
    });

export const createZendeskTicketLogged =
  client =>
  ({ input }) =>
    client.mutate({
      mutation: MUTATION_CREATE_TICKET_ZENDESK_LOGGED,
      variables: { input }
    });

export default createZendeskTicket;
