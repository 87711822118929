import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FooterBottom from '../FooterBottom';

import GlobeLanguageSwitcher from '../GlobeLanguageSwitcher/GlobeLanguageSwitcher';

import AppFooter from '../FooterSimplifiedContainer/AppFooter';

import FooterSection from './components/FooterSection';
import FooterSeparator from './components/FooterSeparator';

import SpotahomeFooter from './SpotahomeFooter';
import TenantFooter from './TenantFooter';
import LandlordFooter from './LandlordFooter';
import HelpFooter from './HelpFooter';
import SocialFooter from './SocialFooter';
import FooterBlock from './components/FooterBlock';

import styles from './Footer.module.scss';
import LegalFooter from './LegalFooter';
import JoinUsFooter from './JoinUsFooter';

export const themeOptions = {
  green: 'green',
  blue: 'blue',
  beige: 'beige'
};

const themeConfig = {
  green: {
    topClass: 'footer--green',
    bottomClass: 'footer__disclaimer--green',
    linkTheme: 'light'
  },
  blue: {
    topClass: 'footer--blue',
    bottomClass: 'footer__disclaimer--blue',
    linkTheme: 'light_underline'
  },
  beige: {
    topClass: 'footer--beige',
    bottomClass: 'footer__disclaimer--beige',
    linkTheme: 'beige'
  }
};

const Footer = ({ theme = themeOptions.blue }) => {
  const { topClass, bottomClass, linkTheme } =
    themeConfig[theme] || themeConfig.green;

  return (
    <footer>
      <section className={classNames(styles.footer, styles[topClass])}>
        <FooterSection>
          <FooterBlock>
            <SpotahomeFooter linkTheme={linkTheme} />
            <LegalFooter linkTheme={linkTheme} />
          </FooterBlock>

          <FooterBlock>
            <TenantFooter linkTheme={linkTheme} />
            <LandlordFooter linkTheme={linkTheme} />
            <JoinUsFooter linkTheme={linkTheme} />
          </FooterBlock>
          <FooterBlock>
            <HelpFooter linkTheme={linkTheme} />
            <GlobeLanguageSwitcher className={styles.footer__app} />
          </FooterBlock>

          <FooterBlock className={styles.footer__tablet}>
            <AppFooter linkTheme={linkTheme} />
            <SocialFooter linkTheme={linkTheme} />
          </FooterBlock>
        </FooterSection>
      </section>
      <section
        className={classNames(styles.footer__disclaimer, styles[bottomClass])}
      >
        <FooterSeparator />

        <FooterSection>
          <FooterBottom />
        </FooterSection>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  theme: PropTypes.oneOf(Object.values(themeOptions))
};

export default memo(Footer);
