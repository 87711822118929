import PropTypes from 'prop-types';

import NotLoggedDesktopButton from './NotLoggedDesktopButton';
import NotLoggedMobileButton from './NotLoggedMobileButton';

const NotLogged = ({ isMobile, ...restProps }) =>
  isMobile ? (
    <NotLoggedMobileButton {...restProps} />
  ) : (
    <NotLoggedDesktopButton {...restProps} />
  );

NotLogged.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default NotLogged;
