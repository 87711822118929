import {
  DateRangeHandler,
  BASE_FEATURES,
  BILLS_INCLUDED
} from '@spotahome/ui-library';

const { getDaysDiff } = DateRangeHandler;

const mapToTrack = (collectionToMap, baseCollection) => {
  if (!(collectionToMap instanceof Object)) {
    return undefined;
  }

  return Object.keys(collectionToMap)
    .filter(id => !!collectionToMap[id] && baseCollection[id])
    .map(id => baseCollection[id].tracking)
    .filter(Boolean)
    .join(', ');
};

export const mapBillsIncluded = bills => mapToTrack(bills, BILLS_INCLUDED);

export const mapHouseRules = houseRules => {
  if (!(houseRules instanceof Object)) {
    return undefined;
  }
  return Object.keys(houseRules)
    .map(r => {
      if (r !== 'gender') {
        return houseRules[r] ? r : `not ${r}`;
      }
      return houseRules[r];
    })
    .join(', ');
};

export const mapHouseFeatures = houseFeatures =>
  mapToTrack(houseFeatures, BASE_FEATURES);

export const mapHomecardTypeToProductCategory = type => {
  switch (type) {
    case 'room_shared':
      return 'room_shared_apt';
    case 'apartment':
      return 'whole';
    case 'studio':
      return 'studio';
    case 'residence':
      return 'room_residence';
    default:
      return type;
  }
};

export const mapSliderElements = tracks => tracks.map(e => e.type).join(', ');

export const getDailyPrice = pricing => pricing.dailyPrice / 100;

export const getContractDaysCount = pricing =>
  getDaysDiff(pricing.startContractDate, pricing.endContractDate);

export const getInstantBookingVariant = listing =>
  listing.instantBooking.isEnabled ? 'instant_booking' : 'regular_booking';
