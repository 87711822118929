export default {
  sansSerif: 'sans-serif',
  serif: 'serif',
  monospaced: 'monospace',
  xxxs: '0.5rem',
  xxs: '0.625rem',
  xs: '0.75rem',
  s: '0.875rem',
  m: '1rem',
  l: '1.125rem',
  xl: '1.25rem',
  xxl: '1.375rem',
  xxxl: '1.625rem',
  xxxxl: '2.125rem',
  xxxxxl: '2.5rem',
  xxxxxxl: '3.5rem',
  xxxxxxxl: '4rem',
  xxxxxxxxl: '4.75rem',
  light: 'PoppinsLight, Poppins-fallback',
  regular: 'PoppinsLight, Poppins-fallback',
  bold: 'PoppinsMedium, Poppins-fallback',
  extraBold: 'PoppinsMedium, Poppins-fallback',
  ultraBold: 'PoppinsMedium, Poppins-fallback',
  title: 'Agrandir, Agrandor'
};
