import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizeMap = {
  small: {
    width: '18px',
    height: '18px'
  },
  normal: {
    width: '24px',
    height: '24px'
  }
};

const CheckBoxUnChecked = ({ size = 'normal' }) => {
  const width = sizeMap[size].width;
  const height = sizeMap[size].height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="checkbox" fillRule="nonzero">
          <g id="Rectangle-1">
            <use fill="white" fillRule="evenodd" />
            <path
              stroke={Colors.light}
              strokeWidth="2"
              d="M1.37202268,15.3657995 L1.68424963,19.6916562 C1.80799129,21.116409 3.07374807,22.418747 4.42499908,22.5503454 L8.70334155,22.8868428 C10.3933694,22.9759886 13.3250682,22.9694313 15.3084306,22.8885197 L19.6021018,22.5516909 C20.9563629,22.4239639 22.1866956,21.128166 22.2821763,19.712243 L22.5702659,15.3527198 C22.6952447,13.4993406 22.6952447,10.4624776 22.5701763,8.60775697 L22.2822659,4.25091652 C22.1870051,2.83824179 20.9609176,1.55464748 19.6213443,1.42811846 L15.3477192,1.10785937 C13.6585713,1.0239504 10.7384991,1.0304817 8.73065573,1.10630028 L4.44780476,1.42680814 C3.07577371,1.55144889 1.81198912,2.84265685 1.68541009,4.24465162 L1.37219453,8.58407425 C1.29029041,10.298805 1.29642797,13.2803345 1.37202316,15.3658061 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

CheckBoxUnChecked.propTypes = {
  size: PropTypes.oneOf(['small', 'normal'])
};

export default CheckBoxUnChecked;
