import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSoyuzBrandConfig } from '@spotahome/soyuz/client';

import styles from './Logo.module.scss';

const getLogoSrc = (brandId, theme) =>
  `https://static.spotahome.com/brand/${brandId}/logo${
    theme === 'light' ? '-light' : ''
  }.svg`;

const Logo = ({ size, theme }) => {
  const brandConfig = useSoyuzBrandConfig();
  const className = classNames(styles.logo, styles[`logo--${size}`]);

  return (
    <img
      alt="logo"
      src={getLogoSrc(brandConfig.id, theme)}
      className={className}
    />
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  theme: PropTypes.oneOf(['dark', 'light'])
};

Logo.defaultProps = {
  size: 'normal',
  theme: 'dark'
};

export default Logo;
