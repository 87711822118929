import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './Header.module.scss';

const cn = classNames.bind(styles);

const Header = ({
  color = 'light',
  shadow = false,
  className = '',
  logo = null,
  menuIcon = null,
  renderLeftContent = () => {},
  renderExtraContent = () => {},
  children = null
}) => {
  const headerClassname = cn(
    styles.header,
    styles[color],
    {
      [`${styles.shadow}`]: shadow
    },
    className
  );

  return (
    <header className={headerClassname}>
      <div className={styles.headerMenu}>
        {!!menuIcon && <div className={styles.menuIcon}>{menuIcon}</div>}
        <div className={styles.headerLogo}>{logo}</div>
        <div className={styles.headerLeftContent}>
          {renderLeftContent && renderLeftContent()}
        </div>

        <div className={menuIcon ? styles.menuClassname : ''}>{children}</div>
        <div className={styles.headerExtraContent}>
          {renderExtraContent && renderExtraContent()}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  color: PropTypes.oneOf(['light', 'dark', 'transparent']),
  shadow: PropTypes.bool,
  className: PropTypes.string,
  logo: PropTypes.element,
  menuIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]),
  renderLeftContent: PropTypes.func,
  renderExtraContent: PropTypes.func
};

export default Header;
