import { useEffect } from 'react';
import { onLCP, onFID, onCLS, onINP } from 'web-vitals/attribution';
import { useSoyuzFlag } from '@spotahome/soyuz/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';
import Metrics from "@spotahome/soyuz-tracking";

const getInpParams = attribution => {
  const { eventEntry, eventTarget, eventType, loadState } = attribution;

  // Get timings from the event timing entry:
  const { startTime, processingStart, processingEnd, duration, interactionId } =
    eventEntry;

  return {
    // The event target (a CSS selector string pointing
    // to the element responsible for the interaction):
    debug_string: eventTarget,
    // The type of event that triggered the interaction:
    metric_event_type: eventType,
    // Whether the page was loaded when the interaction
    // took place. Useful for identifying startup versus
    // post-load interactions:
    metric_load_state: loadState,
    // The time (in milliseconds) after page load when
    // the interaction took place:
    metric_start_time: startTime,
    // When processing of the event callbacks in the
    // interaction started to run:
    metric_processing_start: processingStart,
    // When processing of the event callbacks in the
    // interaction finished:
    metric_processing_end: processingEnd,
    // The total duration of the interaction. Note: this
    // value is rounded to 8 milliseconds of granularity:
    metric_duration: duration,
    // The interaction ID assigned to the interaction by
    // the Event Timing API. This could be useful in cases
    // where you might want to aggregate related events:
    metric_interaction_id: interactionId
  };
};

const sendToGoogleAnalytics = ({ name, delta, value, id, attribution }) => {
  let eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
    debug_string: null
  };

  switch (name) {
    case 'CLS':
      eventParams.debug_target = attribution.largestShiftTarget;
      break;
    case 'FID':
      eventParams.debug_string = attribution.eventTarget;
      break;
    case 'INP':
      eventParams = { ...eventParams, ...getInpParams(attribution) };
      break;
    case 'LCP':
      eventParams.debug_target = attribution.element;
      break;
    default:
      break;
  }

  // Using default set to avoid renaming parameters -- They are "expected" from Reporting tools
  SoyuzAnalytics.sendGA4Event(`cwv-${name}`, eventParams);
  if(name === 'INP') {
    if (delta > 500){
      Metrics.hotjar.triggerEvent('Error INP');
    } else if (delta > 200) {
      Metrics.hotjar.triggerEvent('Warning INP');
    }
  }
};

export const useCoreWebVitals = () => {
  const CoreWebVitalsTrackingFlag = useSoyuzFlag('CoreWebVitalsTracking');
  // This listeners are using the PerformanceObserver API, that stores the generated information until it is used
  useEffect(() => {
    if (CoreWebVitalsTrackingFlag.isOn()) {
      onLCP(sendToGoogleAnalytics);
      onFID(sendToGoogleAnalytics);
      onCLS(sendToGoogleAnalytics);
      onINP(sendToGoogleAnalytics);
    }
  }, []);
};

export default useCoreWebVitals;
