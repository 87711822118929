export const DEFAULT_FONTS = {
  light:
    'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2', // PoppinsLight
  medium:
    'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2', // PoppinsMedium
  title: 'https://static.spotahome.com/fonts/Agrandir-Regular.woff2' // Agrandir
};

const FONT_NAME_CORRELATION = {
  light: 'PoppinsLight',
  medium: 'PoppinsMedium',
  title: 'Agrandir'
};

const FONT_FORMAT_CORRELATION = {
  'eot#iefix': 'embedded-opentype',
  eot: 'embedded-opentype',
  ttf: 'truetype'
};

const FONT_WEIGHTS = {
  light: 300,
  medium: 500,
  title: 400
};

const getFontType = fontSrc => {
  const format = fontSrc.split('.').pop();
  return FONT_FORMAT_CORRELATION[format] || format;
};

const mapFontsFromSet = (brandConfig, fn) => {
  const fontSet = brandConfig.theme?.fontsSrc || DEFAULT_FONTS;
  return Object.entries(fontSet).map(([fontName, fontSrc]) =>
    fn(fontName, fontSrc)
  );
};

export const generateFontPreload = brandConfig => {
  return mapFontsFromSet(brandConfig, (_, fontSrc) => ({
    rel: 'preload',
    href: fontSrc,
    as: 'font',
    type: `font/${getFontType(fontSrc)}`,
    crossOrigin: 'anonymous'
  }));
};

export const generateFonts = brandConfig => {
  return mapFontsFromSet(brandConfig, (fontName, fontSrc) => {
    return `@font-face {
font-family: ${FONT_NAME_CORRELATION[fontName]};
font-style: normal;
font-weight: ${FONT_WEIGHTS[fontName]};
font-display: swap;
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
src: url(${fontSrc}) format(${getFontType(fontSrc)});
}`;
  }).join('\n');
};

export default {
  generateFonts,
  DEFAULT_FONTS,
  generateFontPreload
};
