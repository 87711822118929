import gql from 'graphql-tag';

const SEND_TENANT_ACTION_EVENT = gql`
  mutation sendTenantActionEvent($input: TenantActionEventInput!) {
    sendTenantActionEvent(input: $input)
  }
`;

export const sendTenantActionEvent = client => input =>
  client.mutate({
    mutation: SEND_TENANT_ACTION_EVENT,
    variables: { input }
  });
