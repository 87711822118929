import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 12,
    height: 12
  },
  normal: {
    width: 16,
    height: 16
  },
  big: {
    width: 20,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark,
  medium: Colors.medium
};

const Youtube = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 22 22"
    className={className}
    aria-label="facebook-icon"
  >
    <path
      d="M21.0556 5.21034C21.5156 6.82029 21.5156 10.2702 21.5156 10.2702C21.5156 10.2702 21.5156 13.6818 21.0556 15.33C20.8256 16.25 20.0973 16.94 19.2157 17.17C17.5674 17.5916 11.0509 17.5916 11.0509 17.5916C11.0509 17.5916 4.4961 17.5916 2.84781 17.17C1.96617 16.94 1.23786 16.25 1.00787 15.33C0.547882 13.6818 0.547882 10.2702 0.547882 10.2702C0.547882 10.2702 0.547882 6.82029 1.00787 5.21034C1.23786 4.29036 1.96617 3.56205 2.84781 3.33206C4.4961 2.87207 11.0509 2.87207 11.0509 2.87207C11.0509 2.87207 17.5674 2.87207 19.2157 3.33206C20.0973 3.56205 20.8256 4.29036 21.0556 5.21034ZM8.9043 13.3751L14.3475 10.2702L8.9043 7.16528V13.3751Z"
      fill={THEMES[theme]}
    />
  </svg>
);

Youtube.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'medium'])
};

export default Youtube;
