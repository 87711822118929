import PropTypes from 'prop-types';
import classNames from 'classnames';

import './CarouselArrows.scss';

import ArrowBack from './ArrowBack';
import ArrowForward from './ArrowForward';

const CarouselArrows = ({ page, setPage, maxPage, isRedesign = false }) => {
  const handleSwipePage = value => {
    if ((page === 0 && value === -1) || (page === maxPage - 1 && value === 1)) {
      return;
    }
    setPage(page + value);
  };

  const backDisabled = page === 0;
  const forwardDisabled = page === maxPage - 1;

  const backArrowClasses = classNames('arrow-button', {
    'arrow-button__redesign': isRedesign,
    'arrow-button__redesign--disabled': backDisabled
  });

  const forwardArrowClasses = classNames('arrow-button', {
    'arrow-button__redesign': isRedesign,
    'arrow-button__redesign--disabled': forwardDisabled
  });

  return (
    <div>
      <span
        role="button"
        className={backArrowClasses}
        onClick={() => handleSwipePage(-1)}
        tabIndex={0}
      >
        <ArrowBack disabled={backDisabled} />
      </span>
      <span
        role="button"
        className={forwardArrowClasses}
        onClick={() => handleSwipePage(1)}
        tabIndex={0}
      >
        <ArrowForward disabled={forwardDisabled} />
      </span>
    </div>
  );
};

export default CarouselArrows;

CarouselArrows.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  maxPage: PropTypes.number.isRequired,
  isRedesign: PropTypes.bool
};
