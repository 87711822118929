import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const sizes = {
  small: {
    width: 12,
    height: 12
  },
  normal: {
    width: 16,
    height: 16
  },
  big: {
    width: 20,
    height: 20
  }
};

const THEMES = {
  light: Colors.white,
  dark: Colors.dark,
  medium: Colors.medium
};

const TwitterIcon = ({ className = '', size = 'normal', theme = 'light' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={sizes[size].width}
    height={sizes[size].height}
    viewBox="0 0 23 22"
    className={className}
    aria-label="facebook-icon"
  >
    <path
      d="M17.4973 0.880371H20.7212L13.6779 8.93038L21.9638 19.8846H15.476L10.3946 13.2409L4.58022 19.8846H1.35437L8.88785 11.2742L0.939201 0.880371H7.59167L12.1848 6.95296L17.4973 0.880371ZM16.3658 17.955H18.1522L6.62099 2.70868H4.70399L16.3658 17.955Z"
      fill={THEMES[theme]}
    />
  </svg>
);

TwitterIcon.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'medium'])
};

export default TwitterIcon;
