export default {
  xxxs: '0.875rem',
  xxs: '1rem',
  xs: '1.125rem',
  s: '1.25rem',
  m: '1.5rem',
  l: '1.625rem',
  xl: '1.75rem',
  xxl: '2rem',
  xxxl: '2.125rem',
  xxxxl: '2.625rem',
  xxxxxl: '3rem',
  xxxxxxl: '4.125rem',
  xxxxxxxl: '4.625rem',
  xxxxxxxxl: '5.375rem'
};
