import PropTypes from 'prop-types';

import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const JoinUsFooter = ({ linkTheme }) => {
  const joinUsLinks = [
    {
      url: getPagePath('landings.collaborators'),
      transKey: 'footer.item.collaborators'
    },
    {
      url: getPagePath('landings.become-partner'),
      transKey: 'footer.item.become_partner'
    },
    {
      url: getPagePath('externals.work-with-us'),
      transKey: 'footer.item.work_with_us'
    }
  ];

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.join-us')} />
      <FooterBlockList>
        {joinUsLinks.map(({ transKey, url }) => (
          <FooterLink key={transKey} linkTheme={linkTheme} href={url}>
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

JoinUsFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default JoinUsFooter;
