import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import headersLink from './headersLink';

let client;

const initClient = () => {
  client = new ApolloClient({
    link: headersLink.concat(
      createHttpLink({
        uri: '/marketplace/graphql',
        credentials: 'same-origin'
      })
    ),
    cache: new InMemoryCache({
      addTypename: false
    })
  });

  return client;
};

export default () => client || initClient();
