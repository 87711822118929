import PropTypes from 'prop-types';

import { MultipleItemsCarousel } from './MultipleItemsCarousel';

import City from './City';

// styles
import './CityCarousel.scss';
import CityRedesign from './CityRedesign';

const CityCarousel = ({
  cities,
  page,
  numberOfItems,
  isRedesign = false,
  isMobile = false
}) => {
  const CityComponent = isRedesign ? CityRedesign : City;

  const getSildePercentage = () => {
    if (!isRedesign) {
      return 90;
    }

    return isMobile ? 80 : 95;
  };

  return (
    <div className="city-carousel">
      <MultipleItemsCarousel
        items={cities.map(city => (
          <CityComponent
            key={city.id}
            id={city.id}
            numberOfRentableUnits={city.numberOfRentableUnits}
            country={city.country.countryId.toLowerCase()}
          />
        ))}
        itemsPerSwipe={numberOfItems}
        selectedPage={page}
        isRedesign={isRedesign}
        slidePercentage={getSildePercentage()}
      />
    </div>
  );
};

CityCarousel.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      country: PropTypes.string
    })
  ).isRequired,
  page: PropTypes.number.isRequired,
  numberOfItems: PropTypes.number.isRequired,
  isRedesign: PropTypes.bool,
  isMobile: PropTypes.bool
};

export default CityCarousel;
