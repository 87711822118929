import { getTodayDate, getOneMonthFromTodayDate } from './utils';

export const FORM_URL_OPTIONS = {
  propertyType: {
    explorer: 'for-rent:rooms',
    spiritual: 'for-rent:studios',
    lover: 'for-rent:apartments/for-rent:studios/bedrooms:1/bedrooms:2',
    teamPlayer: 'for-rent:apartments/bedrooms:2/bedrooms:3/bedrooms:3more'
  },
  moveIn: {
    inARush: `move-in=${getTodayDate()}`,
    chilled: `move-in=${getOneMonthFromTodayDate()}`
  },
  rentalType: {
    flirting: 'rentalType[]=shortTerm',
    romance: 'rentalType[]=midTerm',
    trueLove: 'rentalType[]=longTerm'
  },
  budget: {
    lessThan600: 'budget=0-600',
    lessThan900: 'budget=0-900',
    lessThan1200: 'budget=0-1200',
    moreThan1200: 'budget=1200'
  }
};

export const VALUE_PROPOSITION = 0;
export const INTRODUCTION = 1;
export const PROPERTY_TYPE = 2;
export const MOVE_IN = 3;
export const RENTAL_TYPE = 4;
export const CITY = 5;
export const BUDGET = 6;
export const EMAIL = 7;
export const SUCCESS = 8;
export const TOTAL_STEPS = 8;

export default FORM_URL_OPTIONS;
