import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Heading.module.scss';

const cx = classNames.bind(styles);

const Heading = ({
  level = 2,
  theme = 'default',
  size = 'medium',
  type = 'uppercase',
  className = '',
  text,
  align = 'center',
  inline = false
}) => {
  const HeadingClassName = cx(
    styles.root,
    styles[theme],
    styles[size],
    styles[type],
    styles[align],
    inline && styles.inline,
    className
  );

  const CustomTag = `h${level}`;
  const WrapperTag = inline ? 'span' : 'div';

  return (
    <WrapperTag>
      <CustomTag className={HeadingClassName}>{text}</CustomTag>
    </WrapperTag>
  );
};

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  theme: PropTypes.oneOf(['default', 'light', 'dark']),
  size: PropTypes.oneOf(['small', 'small-medium', 'medium', 'large']),
  type: PropTypes.oneOf(['capitalize', 'lowercase', 'uppercase', 'none']),
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  inline: PropTypes.bool
};
export default Heading;
