import PropTypes from 'prop-types';

import classNames from 'classnames';

import GlobeIcon from '../icons/GlobeIcon';
import LanguageSwitcher from '../LanguageSwitcher';

import './GlobeLanguageSwitcher.scss';

const GlobeLanguageSwitcher = ({ className = '' }) => {
  const classes = classNames('globe-language-switcher', className);
  return (
    <span className={classes}>
      <GlobeIcon />
      <LanguageSwitcher theme="dark" />
    </span>
  );
};

GlobeLanguageSwitcher.propTypes = {
  className: PropTypes.string
};

export default GlobeLanguageSwitcher;
