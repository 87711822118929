import Colors from '../../../scss/variables/colors.module.scss';

const COLOR_CORRELATIONS = {
  'web-orange': Colors.warning,
  'blue-cerulean': Colors.interaction,
  'blue-indigo': Colors.interaction,
  'blue-astronaut': Colors.interaction,
  'red-cinnabar': Colors.danger,
  checked: Colors.dark,
  'top-landlord': Colors.secondary,
  offer: Colors.primary,
  'long-term': Colors.interaction,
  dark: Colors.dark,
  secondary: Colors.secondary,
  primary: Colors.primary,
  white: Colors.white,
  medium: Colors.medium
};

export const AVAILABLE_COLORS = Object.keys({
  ...COLOR_CORRELATIONS,
  ...Colors
});

export const getColor = name => COLOR_CORRELATIONS[name] || Colors[name];

export default Colors;
