/* global dataLayer */

/* eslint-disable import/first */
import { useEffect } from 'react';
import NoSSR from 'react-no-ssr';
import Metrics from '@spotahome/soyuz-tracking';

import {
  ThemeProvider,
  CookiesProvider,
  ToastContainer,
  GoogleOneTap,
  SpottyCat,
  UserInfoProvider,
  generateFontPreload,
  TenantTypeProvider,
  AppBanner,
  CitiesProvider,
  Footer
} from '@spotahome/ui-library';
import { useCoreWebVitals } from '@spotahome/ui-library/src/hooks/useCoreWebVitals';
import {
  useSoyuzData,
  useSoyuzBrandConfig,
  useSoyuzExperiment
} from '@spotahome/soyuz/client';

import getGqlClient from '@spotahome/landings-common/src/graphql/client';

import { sendTenantActionEvent } from '@spotahome/landings-common/src/graphql/mutations/tenantActions';

import LoginSignupModal from '@spotahome/landings-common/src/components/LoginSignupModal';
import OurGuarantee from '@spotahome/ui-library/src/tenant/components/OurGuarantee';
import FAQSection from '@spotahome/ui-library/src/tenant/components/FAQSection';

import PropertyTypeSection from '@spotahome/landings-common/src/components/PropertyTypeSection';
import CitiesShowcase from '@spotahome/landings-common/src/components/CitiesShowcase';
import TestimonialsCarousel from '@spotahome/landings-common/src/components/TestimonialsCarousel/TestimonialsCarousel';
import OptInWrapper from '@spotahome/landings-common/src/components/OptInWrapper';

import {
  getTenantFaq,
  getTenantStructuredFaq
} from '@spotahome/landings-common/src/utils/faq';

import FindYourHome from '@spotahome/ui-library/src/tenant/components/FindYourHome';

import {
  CustomDimensionsManager,
  constants
} from '@spotahome/ecommerce-tracking';

import '@spotahome/landings-common/src/scss/init.scss';

import Hero from './components/Hero';
import GuaranteeSection from './components/GuaranteeSection';
import LandlordSection from './components/LandlordSection';
import DepositProtectionSection from './components/DepositProtectionSection';

const getTenantStructuredFaqScript = trans => ({
  type: 'application/ld+json',
  innerHTML: JSON.stringify(getTenantStructuredFaq(trans))
});

const LandingPage = () => {
  const { [LandingPage.NAME]: requestedData } = useSoyuzData();
  const { isPartner } = useSoyuzBrandConfig();

  const isHomepageDepositProtection =
    useSoyuzExperiment('HomepageDepositProtection').get('behaviour') ===
    'variant';

  const { userId, initialCookie, deviceType, isSafari } = requestedData;

  useCoreWebVitals();

  useEffect(() => {
    Metrics.actions.configureClient(async ({ actionId, payload }) => {
      try {
        await sendTenantActionEvent(getGqlClient())({
          actionId,
          payload
        });
      } catch (e) {
        console.log('error sending sendTenantActionEvent:', e);
      }
    });
  }, []);

  useEffect(() => {
    if (userId && typeof dataLayer !== 'undefined') {
      dataLayer.push({ userId });
    }
  }, [userId]);

  return (
    <CookiesProvider initialCookie={initialCookie}>
      <ThemeProvider flags={{ newButtons: true }}>
        <UserInfoProvider>
          <CitiesProvider>
            {!isPartner ? <SpottyCat /> : null}
            <AppBanner source="home" deviceType={deviceType} />
            <ToastContainer />
            {!userId && <GoogleOneTap />}
            <TenantTypeProvider>
              <div data-browser={isSafari ? 'safari' : 'other'}>
                <Hero deviceType={deviceType} />
                {!isPartner && (
                  <>
                    <GuaranteeSection />
                    {isHomepageDepositProtection && (
                      <DepositProtectionSection />
                    )}
                    <TestimonialsCarousel />
                    <OurGuarantee />
                    <PropertyTypeSection deviceType={deviceType} isRedesign />
                    <LandlordSection />
                    <CitiesShowcase deviceType={deviceType} isRedesign />
                    <FAQSection questions={getTenantFaq()} isRedesign />
                    <FindYourHome />
                    <Footer theme="beige" />
                    <OptInWrapper />
                  </>
                )}
                <NoSSR>
                  <LoginSignupModal />
                </NoSSR>
              </div>
            </TenantTypeProvider>
          </CitiesProvider>
        </UserInfoProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
};

LandingPage.requestAllData = async req => {
  try {
    const { locale, userId } = req;
    const cdManager = CustomDimensionsManager();
    cdManager.setCommonCustomDimensions({
      userId,
      locale,
      pageType: constants.TRACKING_PAGE_TYPES.HOMEPAGE
    });

    const isSafari =
      req.device.info && req.device.info.client
        ? req.device.info.client.name.toLowerCase().includes('safari')
        : false;

    return {
      headers: {
        scripts: [
          { innerHTML: cdManager.flush() },
          getTenantStructuredFaqScript(req.trans)
        ],
        links: [...generateFontPreload(req.brandConfig)]
      },
      data: {
        userId: req.userId,
        deviceType: req.device.type,
        initialCookie: req.headers.cookie,
        isSafari
      }
    };
  } catch (err) {
    req.logger.error('Landing page error', { err });
  }

  return {
    headers: {
      links: [...generateFontPreload(req.brandConfig)]
    },
    data: {
      userId: null
    }
  };
};

LandingPage.NAME = 'LandingPage';

export default LandingPage;
