import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Separator.module.scss';

const themes = {
  dark: 'section-separator--dark',
  light: 'section-separator--light'
};

const cx = classNames.bind(styles);

const generateClassName = (theme, className) => {
  const baseClassName = styles['section-separator'];
  const themeClassName = themes[theme] || '';

  return cx(className, baseClassName, styles[themeClassName]);
};

const Separator = ({ theme = 'default', className = '' }) => (
  <hr className={generateClassName(theme, className)} />
);

Separator.propTypes = {
  theme: PropTypes.oneOf(['default', 'light', 'dark']),
  className: PropTypes.string
};

export default Separator;
