import PropTypes from 'prop-types';

import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterBlock from './components/FooterBlock';
import FooterBlockTitle from './components/FooterBlockTitle';
import FooterBlockList from './components/FooterBlockList';
import FooterLink from './components/FooterLink';

const LegalFooter = ({ linkTheme }) => {
  const spotahomeLinks = [
    {
      url: getPagePath('externals.privacy-policies'),
      transKey: 'footer.item.privacy_policies',
      noFollow: true
    },
    {
      url: getPagePath('landings.cookies-policy'),
      transKey: 'footer.item.cookies_policy',
      noFollow: true
    },
    {
      url: getPagePath('externals.terms-and-conditions'),
      transKey: 'footer.item.terms_and_conditions'
    }
  ];

  return (
    <FooterBlock>
      <FooterBlockTitle text={trans('footer.label.legal')} />
      <FooterBlockList>
        {spotahomeLinks.map(({ transKey, url, noFollow }) => (
          <FooterLink
            key={transKey}
            linkTheme={linkTheme}
            href={url}
            rel={noFollow ? 'nofollow' : ''}
          >
            {trans(transKey)}
          </FooterLink>
        ))}
      </FooterBlockList>
    </FooterBlock>
  );
};

LegalFooter.propTypes = {
  linkTheme: PropTypes.string.isRequired
};

export default LegalFooter;
