import PropTypes from 'prop-types';

import MobileLink from './MobileLink';

const LoggedInMobileButton = ({
  myProfilePath,
  myReferralsPath,
  myPaymentsPath,
  userAreaPath,
  searchPreferencePath,
  logoutPath
}) => (
  <span>
    <MobileLink
      href={myProfilePath}
      trans="logout.text.my-profile"
      className="ga-my-profile"
    />
    <MobileLink
      href={searchPreferencePath}
      trans="logout.text.search-preference"
      className="ga-search-preference"
    />
    <MobileLink
      href={userAreaPath}
      trans="logout.text.user-area"
      className="ga-user-area"
    />
    <MobileLink
      href={myReferralsPath}
      trans="header.nav.my-referrals.link"
      className="ga-my-referrals"
    />
    <MobileLink
      href={myPaymentsPath}
      trans="header.nav.my-payments.link"
      className="ga-my-payments"
    />
    <MobileLink
      href={logoutPath}
      trans="logout.text"
      dataTest="logout-mobile-link"
    />
  </span>
);

LoggedInMobileButton.propTypes = {
  myProfilePath: PropTypes.string.isRequired,
  myReferralsPath: PropTypes.string.isRequired,
  myPaymentsPath: PropTypes.string.isRequired,
  userAreaPath: PropTypes.string.isRequired,
  searchPreferencePath: PropTypes.string.isRequired,
  logoutPath: PropTypes.string.isRequired
};

export default LoggedInMobileButton;
