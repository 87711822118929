import Colors from '../utils/colors';
import BackArrow from '../icons/BackArrow';
import IconButton from '../IconButton';

const BackButton = ({ size, ...rest }) => (
  <IconButton {...rest}>
    <BackArrow color={Colors.dark} size={size} />
  </IconButton>
);

BackButton.defaultProps = {
  ...IconButton.defaultProps
};

BackButton.propTypes = {
  ...IconButton.propTypes
};

export default BackButton;
