import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './IconButton.module.scss';

const cx = classNames.bind(styles);

const IconButton = props => {
  const {
    className = '',
    onClick = () => {},
    dataCy = '',
    dataTest = '',
    children,
    size = 'small',
    ...rest
  } = props;

  const closeClassName = cx('icon-button', className);

  return (
    <button
      type="button"
      onClick={onClick}
      className={closeClassName}
      data-cy={dataCy}
      data-test={dataTest}
      size={size}
      {...rest}
    >
      {children}
    </button>
  );
};

IconButton.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  dataCy: PropTypes.string,
  dataTest: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['normal', 'small', 'extra_small'])
};

export default IconButton;
