import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const CollapsibleDown = ({ theme = 'interaction' }) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00012 5.0913L12.4102 0.282349L13.7178 1.75337L7.00012 7.72461L0.282471 1.75337L1.59004 0.282349L7.00012 5.0913Z"
      fill={themes[theme]}
    />
  </svg>
);

CollapsibleDown.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default CollapsibleDown;
