import { trans } from '@spotahome/soyuz/client';

import PaymentMethods from '../PaymentMethods/PaymentMethods';
import FooterBlockTitle from '../FooterContainer/components/FooterBlockTitle';
import GlobeLanguageSwitcher from '../GlobeLanguageSwitcher/GlobeLanguageSwitcher';

import SocialFooter from '../FooterContainer/SocialFooter';
import AppFooter from '../FooterSimplifiedContainer/AppFooter';

import CopyrightFooter from './CopyrightFooter';

import './FooterBottom.scss';

const FooterBottom = () => {
  return (
    <>
      <div className="footer-bottom">
        <CopyrightFooter className="footer-bottom__copyright footer-bottom--show-phablet" />
        <div className="footer-bottom__right-section">
          <span className="footer-bottom__payment-methods">
            <FooterBlockTitle
              inline
              text={trans('footer.item.payment_methods')}
            />
            <PaymentMethods />
          </span>
          <GlobeLanguageSwitcher className="footer-bottom--show-phablet" />
          <span className="footer-bottom--show-mobile">
            <AppFooter />
          </span>
          <span className="footer-bottom--show-mobile">
            <SocialFooter />
          </span>
          <CopyrightFooter
            className="footer-bottom__copyright footer-bottom--show-mobile"
            showIcon={false}
          />
        </div>
      </div>
    </>
  );
};

export default FooterBottom;
