import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../Footer.module.scss';

const cx = classNames.bind(styles);

const FooterBlockList = props => (
  <ul
    className={cx(styles['footer__block-list'], {
      [styles['footer__block-list--social']]: props.hasSocial,
      [styles['footer__block-list--inline']]: props.isInline,
      [styles['footer__block-list--centered']]: props.centered
    })}
  >
    {props.children}
  </ul>
);

FooterBlockList.defaultProps = {
  hasSocial: false,
  isInline: false,
  centered: false
};

FooterBlockList.propTypes = {
  hasSocial: PropTypes.bool,
  isInline: PropTypes.bool,
  centered: PropTypes.bool
};

export default FooterBlockList;
