import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import styles from './ModalBody.module.scss';

const cx = classNames.bind(styles);

const ModalBody = ({ children, className, centered }) => {
  const modalBodyClassName = cx('modalBody', { centered }, className);

  return <div className={modalBodyClassName}>{children}</div>;
};

ModalBody.propTypes = {
  centered: PropTypes.bool
};

export default ModalBody;
