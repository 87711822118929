import gql from 'graphql-tag';

const SET_MARKETING_OPT_IN = gql`
  mutation setMarketingOptIn {
    setMarketingOptIn
  }
`;

const setMarketingOptIn = client => () =>
  client.mutate({
    mutation: SET_MARKETING_OPT_IN
  });

export default setMarketingOptIn;
