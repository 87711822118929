export default {
  /** *************************
   * *************************
   * colors in new palette (https://www.figma.com/file/OehYeVdgon8iPQy6n8z6jv/New-library?node-id=1%3A50)
   * *************************
   * *************************/
  // PRIMARY
  primary: '#FB6E44',
  interaction: '#0a0d77',
  secondaryPink: '#ffccef',
  secondaryBlue: '#C9E3F7',
  interactionDark: '#0D0D0D',
  tertiary: '#F4EFED',
  // SECONDARY
  danger: '#e34242',
  success: '#0cc48c',
  warning: '#ffa800',
  white: '#ffffff',
  // TINTS
  primaryLight: '#FF8540',
  interactionLight: '#3B3992',
  secondaryBlueLight: '#E3F0FB',
  secondaryPinkLight: '#ffe5f7',
  // GREYS
  medium: '#676767',
  light: '#c7c7c7',
  mediumLight: '#EBEBEB',
  ultraLight: '#F7F7F7',
  // OVERLAYS
  overlayLightBlack: 'rgba(0, 0, 0, 0.30)',
  overlayBlack: 'rgba(0, 0, 0, 0.60)',
  overlayWhite: 'rgba(255, 255, 255, 0.80)',
  overlayPink: 'rgba(100, 80, 94, 0.50)',
  // INPUTS
  inputBackground: 'white',
  /** **********************
   * ************************
   * colors not present in the current palette to be deprecated eventually
   * ************************
   * ************************/
  primaryLightBlue: '#C9E3F7', // DEPRECATED: renamed to secondaryBlue
  secondary: '#ffccef', // DEPRECATED: renamed to secondaryPink
  secondaryLight: '#ffe5f7', // DEPRECATED: renamed to secondaryPinkLight
  ultraDark: '#0D0D0D', // DEPRECATED: renamed to interactionDark
  dark: '#0D0D0D', // DEPRECATED: renamed to interactionDark
  info: '#e3f0fb', // DEPRECATED: renamed to secondaryBlueLight
  primaryDark: '#af4d2f', // DEPRECATED
  translucid: 'rgba(255, 255, 255, 80)', // DEPRECATED
  shadow: 'rgba(204, 214, 221, 0.24)' // DEPRECATED
};
